import {ITopOfferComponentService} from "../ITopOfferComponentService";
import {ITopOfferFeatureToggleService} from "../../../services/ITopOfferFeatureToggleService";
import {TariffSelectionType} from "../../../domain/TariffSelectionType";
import {IFilterComponentService} from "../../filter/IFilterComponentService";
export class TopOfferController {
    static $inject:string[] = ["topOfferComponentService", "topOfferFeatureToggleService", "filterComponentService"];

    constructor(private topOfferComponentService:ITopOfferComponentService,
                private topOfferFeatureToggleService:ITopOfferFeatureToggleService,
                private filterComponentService:IFilterComponentService) {}

    public getSeoBottomTeaserText():string {
        return this.topOfferComponentService.getSeoBottomTeaserText();
    }
    public getPlpToggleValue():boolean{
        return this.topOfferFeatureToggleService.isNewBlauPlpEnabled()
    }
    public isTariffSelectionPostpaid(): boolean {
        return this.filterComponentService.getTariffSelection() === TariffSelectionType.POSTPAID;
    }
    public isTariffSelectionHardwareOnly():boolean {
        return this.filterComponentService.getTariffSelection() === TariffSelectionType.HARDWARE_ONLY;
    }
}