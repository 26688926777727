import {MetaInfo, valueMetaInfo} from "@shop/common";
export class StorageOptionContentsValue {
    static META_INFO:MetaInfo = valueMetaInfo('topOffer:StorageOptionContentsValue', {
        optionName: 'string',
        amount: 'number'
    });

    optionName:string;
    amount:number;
}
