import {MetaInfo, valueMetaInfo} from "@shop/common";
import {ConfigurationValue} from "./ConfigurationValue";
import {CriteriaGroupValue} from "./criteria/CriteriaGroupValue";
import {SortingValue} from "./SortingValue";
import {TariffSelectionType} from "./TariffSelectionType";
import {ListElementValue} from "./ListElementValue";
import {PageCalls} from "./PageCalls";
import {FeatureTogglesValue} from "./FeatureTogglesValue";
import {TargetGroupSwitcherValue} from "./TargetGroupSwitcherValue";
import {TopOfferSelectionTypeUrl} from "./TopOfferSelectionTypeUrl";
import {LoginStatusTrackingValue} from "./LoginStatusTrackingValue";

export class TopOfferPageValue {

    static META_INFO:MetaInfo = valueMetaInfo('topOffer:TopOfferPageValue', {
        prepaidListElements: '[ListElementValue]',
        postpaidListElements: '[ListElementValue]',
        hardwareOnlyListElements: '[ListElementValue]',
        criteriaGroups: '[CriteriaGroupValue]',
        sortingValues: '[SortingValue]',
        tariffSelection: 'TariffSelectionType',
        configurations: '{*:ConfigurationValue}',
        calls: 'PageCalls',
        seoHeadlineText: 'string',
        seoTopTeaserText: 'string',
        seoBottomTeaserText: 'string',
        featureTogglesValue: 'FeatureTogglesValue',
        targetGroupSwitcherValue: 'TargetGroupSwitcherValue',
        noOffersAvailableText: 'string',
        unifiedTrackingEnabled: 'boolean',
        topOfferSelectionTypeUrl: 'TopOfferSelectionTypeUrl | null',
        loginStatusTrackingValue: 'LoginStatusTrackingValue'
    });

    prepaidListElements:[ListElementValue];
    postpaidListElements:[ListElementValue];
    hardwareOnlyListElements:[ListElementValue];
    criteriaGroups:CriteriaGroupValue[];
    sortingValues:SortingValue[];
    tariffSelection:TariffSelectionType;
    configurations:{[configurationId:string]:ConfigurationValue};
    calls:PageCalls;
    seoHeadlineText:string;
    seoTopTeaserText:string;
    seoBottomTeaserText:string;
    featureTogglesValue:FeatureTogglesValue;
    targetGroupSwitcherValue: TargetGroupSwitcherValue;
    noOffersAvailableText: string;
    unifiedTrackingEnabled: boolean;
    topOfferSelectionTypeUrl: TopOfferSelectionTypeUrl;
    loginStatusTrackingValue: LoginStatusTrackingValue;
}
