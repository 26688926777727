import {AbstractTechnicalDetailsValue} from "./AbstractTechnicalDetailsValue";
import {valueMetaInfo} from "@shop/common";

export class EmptyTechnicalDetailsValue extends AbstractTechnicalDetailsValue {

    static META_INFO = valueMetaInfo('topOffer:EmptyTechnicalDetailsValue', {
        keyName: 'string | null'
    });

    public isBooleanTypedField():boolean {
        return false;
    }
}
