import {MetaInfo, valueMetaInfo, Content} from "@shop/common";
export class HardwareContentsValue {

    static META_INFO:MetaInfo = valueMetaInfo('topOffer:HardwareContentsValue', {
        name: 'string',
        hardwareImageLink: 'string',
        hardwarePdpLink: 'string',
        overviewOne: 'string',
        overviewTwo: 'string',
        overviewThree: 'string',
        overviewFour: 'string',
        description: 'string',
        ratingStars: 'string',
        ratingReviews: 'Content',
        externalId: 'string'
    });

    name:string;
    overviewOne:string;
    overviewTwo:string;
    overviewThree:string;
    overviewFour:string;
    hardwareImageLink:string;
    hardwarePdpLink:string;
    description:string;
    overview:string[];
    ratingStars:string;
    ratingReviews:Content;
    externalId: string;
}

