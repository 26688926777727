import {valueMetaInfo} from "@shop/common";
export class FeatureTogglesValue {

    static META_INFO = valueMetaInfo('topOffer:FeatureTogglesValue', {
        filtersEnabled: 'boolean',
        totalPriceDisplayEnabled: 'boolean',
        tariffOptionSwitchEnabled: 'boolean',
        newBlauPlpEnabled: 'boolean'
    });

    filtersEnabled:boolean;
    totalPriceDisplayEnabled:boolean;
    tariffOptionSwitchEnabled:boolean;
    newBlauPlpEnabled:boolean

}
