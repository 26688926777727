import {HardwareDetailsController} from "./HardwareDetailsController";
import coHardwareDetailsTemplateHtml from "./coHardwareDetailsTemplate.html";

export class CoHardwareDetails implements ng.IComponentOptions {
    bindings:any = {
        id: '=coHardwareDetailsId'
    };
    controller:any = HardwareDetailsController;
    template:string = coHardwareDetailsTemplateHtml;
}
