import {MetaInfo, valueMetaInfo} from "@shop/common";
import {PackContentsValue} from "./PackContentsValue";
import {PackCalls} from "./PackCalls";
export class PackValue {

    static META_INFO:MetaInfo = valueMetaInfo('topOffer:PackValue', {
        contents: 'PackContentsValue',
        selected: 'boolean',
        calls: 'PackCalls'
    });

    contents:PackContentsValue;
    selected:boolean;
    calls:PackCalls;
}
