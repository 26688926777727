import {MetaInfo, valueMetaInfo} from "@shop/common";
import {TariffContentsValue} from "./TariffContentsValue";
import {PackGroupValue} from "./PackGroupValue";
export class TariffValue {

    static META_INFO:MetaInfo = valueMetaInfo('topOffer:TariffValue', {
        contents: 'TariffContentsValue',
        packGroups: '[PackGroupValue]'

    });

    contents:TariffContentsValue;
    packGroups:PackGroupValue[];
}
