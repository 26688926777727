import {MetaInfo, valueMetaInfo} from "@shop/common";
export class TariffContentsValue {

    static META_INFO:MetaInfo = valueMetaInfo('topOffer:TariffContentsValue', {
        name: 'string',
        description: 'string',
        overviewOne: 'string',
        overviewTwo: 'string',
        overviewThree: 'string',
        overviewFour: 'string',
        dataVolume: 'string',
        bandwidth: 'string',
        phoneFlat: 'string',
        smsFlat: 'string',
        roaming: 'string',
        conditions: 'string',
        numberPorting: 'string',
        footnotes: 'string'
    });

        name:string;
        description:string;
        overviewOne: string;
        overviewTwo: string;
        overviewThree: string;
        overviewFour: string;
        dataVolume: string;
        bandwidth: string;
        phoneFlat: string;
        smsFlat: string;
        roaming: string;
        conditions:string;
        numberPorting:string;
        footnotes:string;
    }


