import {ITabsComponentService} from "../ITabsComponentService";
import {CriteriaValue} from "../../../domain/criteria/CriteriaValue";
import {IUiStateService} from "../../../services/IUiStateService";
import _ = require("underscore");
export class TabsController {
    static $inject:string[] = ['tabsComponentService', 'uiStateService'];

    constructor(private tabsComponentService:ITabsComponentService,
                private uiStateService:IUiStateService) {}

    public isFilterDisabled():boolean {
        return this.tabsComponentService.isFilterDisabled();
    }
    public switchManufacturer(criteriaKeyValue:string):void {
        this.uiStateService.setFilterStatus(true);
        this.tabsComponentService.switchManufacturer(criteriaKeyValue);
    }
    public getManufacturerCriteriaValues():{[csSclass:string]:CriteriaValue} {
        return this.tabsComponentService.getManufacturerCriteriaValues();
    }

    public showAllManufacturer():void {
        return this.tabsComponentService.showAllManufacturer();
    }

    public isNoActiveManufacturer():boolean {
        let foundManufacturer:CriteriaValue = _.find(this.tabsComponentService.getManufacturerCriteriaValues(), (criteriaValue:CriteriaValue) => {
            return criteriaValue.selected;
        });

        return foundManufacturer === undefined;
    }

    public isActiveManufacturer(criteriaKeyValue:string):boolean {
        let foundManufacturer:CriteriaValue = _.find(this.tabsComponentService.getManufacturerCriteriaValues(), (criteriaValue:CriteriaValue) => {
            return criteriaValue.criteriaKeyValue === criteriaKeyValue;
        });

        return foundManufacturer.selected;
    }

    public getSeoHeadlineText():string {
        return this.tabsComponentService.getSeoHeadlineText();
    }

    public getSeoTopTeaserText():string {
        return this.tabsComponentService.getSeoTopTeaserText();
    }
}
