import {StorageOptionValue} from "../../domain/hardware/StorageOptionValue";
import {ColorOptionValue} from "../../domain/hardware/ColorOptionValue";
import {TariffOptionValue} from "../../domain/TariffOptionValue";
import {StockHintValue} from "../../domain/hardware/StockHintValue";
import {PriceValue} from "../../domain/PriceValue";
import {IDataService} from "../../services/IDataService";
import {IConfigurationChangeService} from "../../services/IConfigurationChangeService";
import {IOffersComponentService} from "./IOffersComponentService";
import {IUiStateService} from "../../services/IUiStateService";
import {MediaGalleryValue} from "../../domain/hardware/MediaGalleryValue";
import {MediaGalleryItemType} from "../../domain/hardware/MediaGalleryItemType";
import {IListElementService} from "../../services/IListElementService";
import {ITopOfferFeatureToggleService} from "../../services/ITopOfferFeatureToggleService";
import {Content} from "@shop/common";
import {ConfigurationValue} from "../../domain/ConfigurationValue";
import _ = require("underscore");
import {TopOfferTrackingService} from "../../tracking/TopOfferTrackingService";

export class OffersComponentService implements IOffersComponentService {
    static $inject:string[] = [
        'dataService',
        'configurationChangeService',
        'uiStateService',
        'listElementService',
        'topOfferFeatureToggleService',
        'topOfferTrackingService'

    ];

    constructor(private dataService:IDataService,
                private configurationChangeService:IConfigurationChangeService,
                private uiStateService:IUiStateService,
                private listElementService:IListElementService,
                private topOfferFeatureToggleService:ITopOfferFeatureToggleService,
                private topOfferTrackingService: TopOfferTrackingService) {
    }

    public submitSelected(id:string):void {
        if(this.isSubmitPossible(id)) {
            this.uiStateService.setSubmitInProgress(true); // prevent submit as soon as user clicked the submit button
            const hwIndex = this.listElementService.getVisibleListElements().map(function(value) { return value.id;}).indexOf(id);
            this.topOfferTrackingService.trackProductClick(this.getConfiguration(id).ecommerceProductValue,this.getSelectedCriteriaValue(),hwIndex);
            var promise = this.dataService.submitSelected(id);
            promise.catch(() => {
                this.uiStateService.setSubmitInProgress(false);
            });
        }
    }

    public isSubmitPossible(id:string):boolean {
        return !this.uiStateService.getSubmitInProgress() && this.dataService.isSubmitEnabled(id);
    }

    public isPostpaidSelected():boolean {
        return this.listElementService.isPostpaidSelected();
    }

    public isPrepaidSelected():boolean {
        return this.listElementService.isPrepaidSelected();
    }

    public isHardwareOnlySelected():boolean {
        return this.listElementService.isHardwareOnlySelected();
    }

    public getPrices(id:string):PriceValue {
        return this.dataService.getPrices(id);
    }

    public getFootnotes(id:string):string {
        return this.dataService.getFootnotes(id);
    }

    public getStockHint(id:string):StockHintValue {
        return this.dataService.getStockHint(id);
    }

    public getHardwareName(id:string):string {
        return this.dataService.getHardwareContents(id).name;
    };

    public getHardwareImageLink(id:string):string {
        return this.dataService.getHardwareContents(id).hardwareImageLink;
    };

    public getHardwarePdpLink(id:string):string {
        return this.dataService.getHardwareContents(id).hardwarePdpLink;
    }

    public getHardwareDescription(id:string):string {
        return this.dataService.getHardwareContents(id).description;
    };

    public getHardwareColors(id:string):ColorOptionValue[] {
        return this.dataService.getHardwareColors(id);
    };

    public getHardwareStorages(id:string):StorageOptionValue[] {
        return this.dataService.getHardwareStorages(id);
    };

    public getTariffFeatures(id:string):string[] {
        return this.dataService.getTariffFeatures(id);
    }

    public getTariffOptionEntries(id:string):TariffOptionValue[] {
        return this.dataService.getConfiguration(id).tariffOptionEntries;
    };

    public setHardwareColor(id:string, color:ColorOptionValue):void {
        this.configurationChangeService.setHardwareColor(id, color);
    }

    public setHardwareStorage(id:string, storage:StorageOptionValue):void {
        this.configurationChangeService.setHardwareStorage(id, storage);
    }

    public getPriceInteger(price:number):string {
        return this.dataService.getPriceInteger(price);
    }

    public getPriceDecimal(price:number):string {
        return this.dataService.getPriceDecimal(price);
    }

    public formatPrice(
        price: number,
        withoutTrailingZeros: boolean = false
    ): string {
        const integer = this.getPriceInteger(price);
        const decimal = this.getPriceDecimal(price);

        if (decimal === "00" && withoutTrailingZeros) {
            return integer;
        }

        return `${this.getPriceInteger(price)},${this.getPriceDecimal(price)}`;
    }

    public selectOptionEntry(id:string, selectedEntry:TariffOptionValue):void {
        this.configurationChangeService.selectTariffOptionEntry(id, selectedEntry);
    }

    public isShowMediaGallery():boolean {
        return this.uiStateService.isShowMediaGallery();
    }

    public openMediaGallery(id:string):void {
        this.uiStateService.openMediaGallery(id);
    }

    public closeMediaGallery():void {
        this.uiStateService.closeMediaGallery();
    }

    public getMediaGallery():MediaGalleryValue {
        let id = this.uiStateService.getActiveMediaGalleryId();
        return this.dataService.getMediaGallery(id);
    }

    public getActiveMediaGalleryItemType():MediaGalleryItemType {
        let id = this.uiStateService.getActiveMediaGalleryId();
        if (_.isNull(this.uiStateService.getActiveMediaGalleryItemType())) {
            this.uiStateService.setActiveMediaGalleryItemType(this.dataService.getInitialMediaGalleryItemType(id));
        }
        return this.uiStateService.getActiveMediaGalleryItemType();
    }

    public setActiveMediaGalleryItemType(type:MediaGalleryItemType) {
        this.uiStateService.setActiveMediaGalleryItemType(type);
    }

    public isMediaGalleryAvailable(id:string):boolean {
        return this.dataService.isMediaGalleryAvailable(id);
    }

    public isAllDetailsOpen(id:string):boolean {
        return this.uiStateService.isAllDetailsOpen(id);
    }

    public toggleAllDetails(id:string):void {
        this.uiStateService.toggleAllDetails(id);
    }

    public isTotalPriceDisplayEnabled():boolean {
        return this.topOfferFeatureToggleService.isTotalPriceDisplayEnabled();
    }

    public getRatingStars(id:string) {
        return this.dataService.getRatingStars(id);
    }

    public openReviewsModal(id:string):void {
        this.uiStateService.openReviewsModal(id);
    }

    public closeReviewsModal():void {
        this.uiStateService.closeReviewsModal();
    }

    public isReviewsModalOpen():boolean {
        return this.uiStateService.isReviewsModalOpen();
    }

    public getRatingReviews():Content {
        let id = this.uiStateService.getActiveReviewsModalId();
        if(_.isEmpty(id)) {
            return '';
        }
        return this.dataService.getRatingReviews(id);
    }

    public getActiveReviewsHardwareName():string {
        let id = this.uiStateService.getActiveReviewsModalId();
        if(_.isEmpty(id)) {
            return '';
        }
        return this.dataService.getHardwareContents(id).name;
    }

    public getExternalId(id:string):string {
        return this.dataService.getExternalId(id);
    }

    public getNoOffersAvailableText(): string {
        return this.dataService.getNoOffersAvailableText();
    }
    getConfiguration(id: string): ConfigurationValue {
        return this.dataService.getConfiguration(id);
    }
    getSelectedCriteriaValue(): string {
        let criteriaValue = this.listElementService.getCurrentCriteriaValue() == "" ? "Alle" : this.listElementService.getCurrentCriteriaValue().split("/")[2];
        criteriaValue = criteriaValue == undefined ? 'sonstige' : criteriaValue ;
        const productType =  this.listElementService.isHardwareOnlySelected() ? "HW_ONLY" : "BUNDLE";
        const selectedFilterValue = `${this.dataService.getSeoHeadlineText()}/${productType}/${criteriaValue}_${this.listElementService.getVisibleListElements().length}/privatkunden/${this.listElementService.getSelectedSortingEntry().sortingName}/no_url_params`;
        return selectedFilterValue;
    }

    public setFilterStatus(filterclicked:boolean):void {
        this.uiStateService.setFilterStatus(filterclicked);
    }

    public getFilterStatus():boolean {
        return this.uiStateService.getFilterStatus();
    }

    public getTariffDataVolume(id:string):string{
        return this.dataService.getTariffDataVolume(id);
    }

}
