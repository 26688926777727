import {OfferHardwareStoragesController} from "./OfferHardwareStoragesController";
import  coOfferHardwareStoragesTemplateHtml  from "./coOfferHardwareStoragesTemplate.html";

export class CoOfferHardwareStorages implements ng.IComponentOptions {
    bindings:any = {
        id: '=coOfferHardwareStoragesId'
    };
    controller:any = OfferHardwareStoragesController;
    template:string = coOfferHardwareStoragesTemplateHtml;
}
