import {ConfigurationValue} from "../ConfigurationValue";
import { valueMetaInfo, MetaInfo, GetCall } from "@shop/common";

export class StorageOptionCalls {
    static META_INFO:MetaInfo = valueMetaInfo('topOffer:StorageOptionCalls', {
        changeStorageOptionCall: 'GetCall'
    });

    changeStorageOptionCall:GetCall<ConfigurationValue>;
}
