import {enumMetaInfo, MetaInfo} from "@shop/common";
export class CriteriaGroupType {

    static META_INFO:MetaInfo = enumMetaInfo('topOffer:CriteriaGroupType');

    private static _values:{ [s:string]:CriteriaGroupType; } = {};

    static forName(obj):CriteriaGroupType {
        argsContract(arguments, 'string | null');
        if (obj === null) {
            return null;
        }
        return CriteriaGroupType._values[obj];
    }

    static values():CriteriaGroupType[] {
        return _.values(CriteriaGroupType._values);
    }


    static MANUFACTURER:CriteriaGroupType = new CriteriaGroupType("MANUFACTURER");
    static OTHER:CriteriaGroupType = new CriteriaGroupType("OTHER");

    constructor(public name:string) {
        argsContract(arguments, 'str');
        CriteriaGroupType._values[name] = this;
    }

}

