import {TechnicalDetailsGroupValue} from "../../../domain/hardware/TechnicalDetailsGroupValue";
import {IProductDetailsComponentService} from "../IProductDetailsComponentService";
import IAngularEvent = angular.IAngularEvent;
import _ = require("underscore");

export class HardwareDetailsController {

    static $inject:string[] = ['productDetailsComponentService'];

    private id:string;

    constructor(private productDetailsComponentService:IProductDetailsComponentService) {
    }

    public getHardwareName():string {
        return this.productDetailsComponentService.getHardwareName(this.id);
    }

    public getHardwareOverviewItems():string[] {
        return this.productDetailsComponentService.getHardwareOverviewItems(this.id);
    }

    public isHardwareOverviewItemsEmpty():boolean {
        return _.isEmpty(this.getHardwareOverviewItems());
    }

    public getTransformedHardwareTechnicalDetailGroups():TechnicalDetailsGroupValue[] {
        return this.productDetailsComponentService.getTransformedHardwareTechnicalDetailGroups(this.id);
    }

    public isAccordionEntryOpen(entryId:string):boolean {
        return this.productDetailsComponentService.isHardwareAccordionEntryOpen(this.id, entryId);
    }

    public toggleAccordionEntry(entryId:string, $event:IAngularEvent):void {
        $event.preventDefault();
        this.productDetailsComponentService.toggleHardwareAccordionEntry(this.id, entryId);
    }
}
