import {MetaInfo, valueMetaInfo} from "@shop/common";
import {MediaGalleryItemValue} from "./MediaGalleryItemValue";
export class MediaGalleryValue {

    static META_INFO:MetaInfo = valueMetaInfo('topOffer:MediaGalleryValue', {
        items: '[MediaGalleryItemValue]'
    });

    items:MediaGalleryItemValue[];
}
