import { IConfigurationChangeService } from './IConfigurationChangeService';
import { IDataService } from './IDataService';
import { ColorOptionValue } from '../domain/hardware/ColorOptionValue';
import { StorageOptionValue } from '../domain/hardware/StorageOptionValue';
import { PackGroupValue } from '../domain/tariff/PackGroupValue';
import { PackValue } from '../domain/tariff/PackValue';
import { ICallService, GetCall, Call, IBusyService, ITrackingService} from "@shop/common";
import { ConfigurationValue } from '../domain/ConfigurationValue';
import { TariffOptionValue } from '../domain/TariffOptionValue';

export class ConfigurationChangeService implements IConfigurationChangeService {
    static $inject:string[] = ['dataService', 'callService', 'busyService', 'trackingService'];

    constructor(private dataService:IDataService, private callService:ICallService, private busyService:IBusyService, private trackingService:ITrackingService) {
    }

    public setHardwareColor(id:string, newActiveColor:ColorOptionValue):void {
        var call:GetCall<ConfigurationValue> = newActiveColor.calls.changeColorOptionCall;
        this.getNewConfiguration(call, id)
    }

    public setHardwareStorage(id:string, newActiveStorage:StorageOptionValue):void {
        var call:Call<ConfigurationValue> = newActiveStorage.calls.changeStorageOptionCall;
        this.getNewConfiguration(call, id)
    }

    public selectTariffOptionEntry(id:string, newSelectedEntry:TariffOptionValue):void {
         var call:Call<ConfigurationValue> = newSelectedEntry.calls.changeTariffCall;
        this.getNewConfiguration(call, id)
    }

    public selectPack(id:string, packGroupIndex:number, packGroup:PackGroupValue, newActivePack:PackValue):void {
        var call:Call<ConfigurationValue> = newActivePack.calls.changePackCall;
        this.getNewConfiguration(call, id);
    }

    private getNewConfiguration(call:Call<ConfigurationValue>, id:string):void {
        this.busyService.busy(
            this.callService.handleCall(call).then((newConfiguration:ConfigurationValue) => {
                this.dataService.setConfiguration(id, newConfiguration);
                this.trackingService.track(newConfiguration.calls.trackingCall);
            })
        );
    }
}
