import {TariffPacksController} from "./TariffPacksController";
import coTariffPacksTemplateHtml from "./coTariffPacksTemplate.html";

export class CoTariffPacks implements ng.IComponentOptions {
    bindings:any = {
        id: '=coTariffPacksId',
        packGroup: '<coTariffPacksPackGroup',
        packGroupIndex: '<coTariffPacksPackGroupIndex'
    };

    controller:any = TariffPacksController;
    template:string = coTariffPacksTemplateHtml;
}