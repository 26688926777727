import {HardwareMediaController} from "./HardwareMediaController";
import coHardwareMediaTemplateHtml from "./coHardwareMediaTemplate.html";
export function coHardwareMedia():ng.IDirective {
    return {
        scope: {
            id: '=coHardwareMediaId'
        },
        replace: true,
        template: coHardwareMediaTemplateHtml,
        controller: <any>HardwareMediaController,
        bindToController: true,
        controllerAs: '$ctrl'
    };
}

