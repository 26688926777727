import {PriceDisplayController} from "./PriceDisplayController";
import coPriceDisplayTemplateHtml from "./coPriceDisplayTemplate.html";

export class CoPriceDisplay implements ng.IComponentOptions {
    bindings:any = {
        type: '@coPriceDisplayType',
        prices: '<coPriceDisplayPrices'
    };
    controller:any = PriceDisplayController;
    template:string = coPriceDisplayTemplateHtml;
}
