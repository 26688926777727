import {IOffersComponentService} from "../IOffersComponentService";
import IAngularEvent = angular.IAngularEvent;
import {IFilterComponentService} from "../../filter/IFilterComponentService";
import {Content} from "@shop/common";
import {TopOfferTrackingService} from "../../../tracking/TopOfferTrackingService";

export class OffersController {
    static $inject:string[] = ['offersComponentService', 'filterComponentService', 'topOfferTrackingService'];
    alreadyTrackedProducts = [];
    constructor(private offersComponentService:IOffersComponentService,
                private filterComponentService:IFilterComponentService,
                private topOfferTrackingService: TopOfferTrackingService) {
        // Add event listener to window object for load event
        window.addEventListener('load', () => {
            // Call the pushTrackedProductsToDataLayer() function on load
            this.pushTrackedProductsToDataLayer();
        });

        // Add event listener to window object for scroll event
        window.addEventListener('scroll', () => {
            // Call the pushTrackedProductsToDataLayer() function on scroll
            this.pushTrackedProductsToDataLayer();
        });
    }

    public getListElementIds():String[] {
        return this.filterComponentService.getListElementIds();
    }

    public moreRowsToShow(): boolean {
        return this.filterComponentService.moreRowsToShow();
    }

    public showMoreRows(): void {
        this.filterComponentService.showMoreRows();
    }

    public isAllDetailsOpen(id:string):boolean {
        return this.offersComponentService.isAllDetailsOpen(id);
    }

    public toggleAllDetails(id:string, $event:IAngularEvent):void {
        $event.preventDefault();
        this.offersComponentService.toggleAllDetails(id);
    }

    public closeReviewsModal():void {
        this.offersComponentService.closeReviewsModal();
    }

    public isReviewsModalOpen():boolean {
        return this.offersComponentService.isReviewsModalOpen();
    }

    public getRatingReviews():Content {
        return this.offersComponentService.getRatingReviews();
    }

    public getActiveReviewsHardwareName():string {
        return this.offersComponentService.getActiveReviewsHardwareName();
    }

    public getExternalId(id:string):string {
        return this.offersComponentService.getExternalId(id);
    }

    public modalContentResolved(){
        $(document).trigger('tel.app.init');
    }

    public getNoOffersAvailableText(): string{
        return this.offersComponentService.getNoOffersAvailableText();
    }
    private pushTrackedProductsToDataLayer() {
        if(this.offersComponentService.getFilterStatus()){
            this.alreadyTrackedProducts = [];
            this.offersComponentService.setFilterStatus(false);
        }
        const childrenDivIds = document.querySelectorAll("#main-offers-container div[id]");
        const visitedDivIds = [];

        for (var i = 0; i < childrenDivIds.length; i++) {
            var divId = childrenDivIds[i].id;
            var divTop = childrenDivIds[i].getBoundingClientRect().top;
            var divBottom = childrenDivIds[i].getBoundingClientRect().bottom;

            if (divTop < window.innerHeight && divBottom >= 0) {
                visitedDivIds.push(divId);
            }
        }
        const allHwProducts = this.getListElementIds();
        const visitedHwProducts = allHwProducts.filter(hw => visitedDivIds.some(visitedId => visitedId === hw));
        const trackingHwProducts = visitedHwProducts.filter(hw => !this.alreadyTrackedProducts.some(trackedProduct => trackedProduct === hw));

        if (trackingHwProducts.length >= 6) {
            // Push tracking data to window.tefDataLayer
            // @ts-ignore
            const ids = trackingHwProducts.slice(0, 6);
            const ecommerceProductValues = [];
            for (let i = 0; i < ids.length; i++) {
                // @ts-ignore
                const ecommerceProductValue = this.offersComponentService.getConfiguration(ids[i]).ecommerceProductValue;
                if (ecommerceProductValue !== null) {
                    ecommerceProductValues.push(ecommerceProductValue);
                }
            }
            this.topOfferTrackingService.buildTrackingObjectForProductImpression(ecommerceProductValues, this.offersComponentService.getSelectedCriteriaValue(), this.alreadyTrackedProducts.length);
            this.alreadyTrackedProducts.push(...trackingHwProducts.slice(0, 6));
        } else if (trackingHwProducts.length > 0) {
            // Push tracking data to window.tefDataLayer
            // @ts-ignore
            const ids = trackingHwProducts;
            const ecommerceProductValues = [];
            for (let i = 0; i < ids.length; i++) {
                // @ts-ignore
                const ecommerceProductValue = this.offersComponentService.getConfiguration(ids[i]).ecommerceProductValue;
                if (ecommerceProductValue !== null) {
                    ecommerceProductValues.push(ecommerceProductValue);
                }
            }
            this.topOfferTrackingService.buildTrackingObjectForProductImpression(ecommerceProductValues, this.offersComponentService.getSelectedCriteriaValue(), this.alreadyTrackedProducts.length);
            this.alreadyTrackedProducts.push(...trackingHwProducts);
        }
    }
}