import {IFilterComponentService} from "../IFilterComponentService";
import {TariffSelectionType} from "../../../domain/TariffSelectionType";

export class TariffOptionsController {

    static $inject:string[] = ['filterComponentService'];

    constructor(private filterComponentService:IFilterComponentService) {
    }

    public isTariffSelectionHardwareOnly():boolean {
        return this.filterComponentService.getTariffSelection() === TariffSelectionType.HARDWARE_ONLY;
    }

    public setTariffSelectionHardwareOnly(): void {
        this.filterComponentService.setTariffSelection(TariffSelectionType.HARDWARE_ONLY);
        // Checking current window location is bundle hardware catalog url, in that case user want to
        // see devices with hardware only selection, then it re-direct on hardware only hw catalog page.
        if (null != this.filterComponentService.getBundleHwCatalogUrl() &&
            window.location.href === this.filterComponentService.getBundleHwCatalogUrl()) {
            window.location.replace(this.filterComponentService.getHardwareOnlyHwCatalogUrl());
        }
    }

    public isTariffSelectionPostpaid(): boolean {
        return this.filterComponentService.getTariffSelection() === TariffSelectionType.POSTPAID;
    }

    public setTariffSelectionPostpaid(): void {
        this.filterComponentService.setTariffSelection(TariffSelectionType.POSTPAID);
        // Checking current window location is hw only hardware catalog url, in that case user want
        // to see devices with bundle selection, then it re-direct on bundle hw catalog page.
        if (null != this.filterComponentService.getHardwareOnlyHwCatalogUrl() && (window.location.href === this.filterComponentService.getHardwareOnlyHwCatalogUrl()) ||
            (window.location.href === this.filterComponentService.getHardwareOnlyHwCatalogUrl().substring(0, this.filterComponentService.getHardwareOnlyHwCatalogUrl().length - 1))) {
            window.location.replace(this.filterComponentService.getBundleHwCatalogUrl());
        }
    }

    public isTariffSelectionPrepaid():boolean {
        return this.filterComponentService.getTariffSelection() === TariffSelectionType.PREPAID;
    }

    public setTariffSelectionPrepaid():void {
        this.filterComponentService.setTariffSelection(TariffSelectionType.PREPAID);
    }
}
