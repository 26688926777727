import {MetaInfo, valueMetaInfo} from "@shop/common";
export class ColorOptionContentsValue {

    static META_INFO:MetaInfo = valueMetaInfo('topOffer:ColorOptionContentsValue', {
        optionName: 'string',
        imageHtml: 'string | null'
    });

    optionName:string;
    imageHtml:string;

    get imageUrl():String {
        // since the image HTML snippet from the CMS does not have a root element,
        // the content must be wrapped in a dummy, root <div> element for the
        // jQuery selectors to work
        return $('<div>').append($.parseHTML(this.imageHtml)).find('img').attr('src');
    }

}
