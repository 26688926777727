

import {TariffOptionValue} from "../../../domain/TariffOptionValue";
import { getFormattedDataVolumeGigabytes } from "../../../util/helpers";

export class CoTariffSwitcherController {
    static $inject:string[] = ['offersComponentService'];

    private entries: TariffOptionValue[] = [];
    private _selectedEntry: TariffOptionValue;


    public onEntryClick(entry: TariffOptionValue){
        this._selectedEntry = entry;
    }

    set selectedEntry(entry: TariffOptionValue) {
        this._selectedEntry = entry;
    }

    get selectedEntry():TariffOptionValue {
        return this._selectedEntry;
    }


    public getFormattedDataVolumeGigabytes(dataVolumeMbNumeric: string, tariffName: string) {
        return getFormattedDataVolumeGigabytes(
            dataVolumeMbNumeric,
            tariffName,
            ''
        );
    }

    public hasTariffOptions() {
        return this.getEntries().length > 0;
    }
    public getEntries():TariffOptionValue[] {
        return this.entries;
    };
}