import {OfferHardwareColorsController} from "./OfferHardwareColorsController";
import  coOfferHardwareColorsTemplateHtml  from "./coOfferHardwareColorsTemplate.html";

export class CoOfferHardwareColors implements ng.IComponentOptions {
    bindings:any = {
        id: '=coOfferHardwareColorsId'
    };
    controller:any = OfferHardwareColorsController;
    template:string = coOfferHardwareColorsTemplateHtml;
}
