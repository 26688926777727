import {StockHintValue} from "../../../domain/hardware/StockHintValue";
import {StockHintType} from "../../../domain/hardware/StockHintType";
import {IOffersComponentService} from "../IOffersComponentService";
export class OfferStockHintController {

    static $inject:string[] = ['offersComponentService'];

    private id:string;

    constructor(private offersComponentService:IOffersComponentService) {
    }

    public getStockHintText():string {
        return this.getStockHint().contents.hint;
    }

    public getCssClassForStockHint():string {
        let stockHint:StockHintValue = this.getStockHint();

        switch (stockHint.hintType) {
            case StockHintType.OUT_OF_STOCK:
                return 'not-available';
            case StockHintType.ON_STOCK_LIMITED_AVAILABILITY:
                return 'short';
            case StockHintType.ON_STOCK:
            case StockHintType.VIRTUAL_STOCK:
                return 'available';
            default:
                return '';
        }
    }

    private getStockHint():StockHintValue {
        return this.offersComponentService.getStockHint(this.id);
    }

}
