import {IDataService} from "../../services/IDataService";
import {IFilterComponentService} from "./IFilterComponentService";
import {TariffSelectionType} from "../../domain/TariffSelectionType";
import {IListElementService} from "../../services/IListElementService";
import {CriteriaValue} from "../../domain/criteria/CriteriaValue";
import {CriteriaGroupValue} from "../../domain/criteria/CriteriaGroupValue";
import {ListElementValue} from "../../domain/ListElementValue";
import {SortingEntry} from "../../services/IListElementService";
import {ITopOfferFeatureToggleService} from "../../services/ITopOfferFeatureToggleService";

export class FilterComponentService implements IFilterComponentService {

    static $inject:string[] = [
        "dataService",
        "listElementService",
        "topOfferFeatureToggleService"
    ];

    constructor(private dataService:IDataService,
                private listElementService:IListElementService,
                private topOfferFeatureToggleService:ITopOfferFeatureToggleService) {
    }

    public showAllManufacturer():void {
        return this.listElementService.showAllManufacturer();
    }

    public switchManufacturer(criteriaKeyValue:string):void {
        return this.listElementService.switchManufacturer(criteriaKeyValue);
    }

    public toggleCriteriaValues(criteriaKeyValue:string):void{
        return this.listElementService.toggleCriteriaValue(criteriaKeyValue);
    }

    public getCriteriaValues():CriteriaGroupValue[]{
        return this.dataService.getAllCriteriaGroup();
    }

    public resetCriteriaSelection():void {
        this.listElementService.resetCriteriaSelection();
    }

    public getManufacturerCriteriaValues():{[csSclass:string]:CriteriaValue} {
        return this.dataService.getManufacturerCriteriaGroup();
    }

    public getTariffSelection():TariffSelectionType {
        return this.listElementService.getTariffSelection();
    }

    public setTariffSelection(type:TariffSelectionType):void {
        this.listElementService.setTariffSelection(type);
    }

    public getListElementIds():string[]{
        return this.listElementService.getVisibleListElements().map((element:ListElementValue) => element.id)
    }

    public moreRowsToShow():boolean {
        return this.listElementService.moreRowsToShow();
    }
    
    public showMoreRows():void{
        return this.listElementService.showMoreRows();
    }
    
    public isFilterDisabled():boolean {
        return !this.topOfferFeatureToggleService.isFiltersEnabled();
    }
    
    public isTariffOptionSwitchEnabled():boolean {
        return this.topOfferFeatureToggleService.isTariffOptionSwitchEnabled();
    }

    public navigateToAllDevices():void {
        this.dataService.navigateToAllDevices();
    }

    public getSortingEntries():SortingEntry[] {
        return this.listElementService.getSortingEntries();
    }

    public setSelectedSortingEntry(entry:SortingEntry) {
        this.listElementService.setSelectedSortingEntry(entry);
    }

    public getSelectedSortingEntry():SortingEntry {
        return this.listElementService.getSelectedSortingEntry();
    }

    public getBundleHwCatalogUrl(): string {
        return this.dataService.getBundleHwCatalogUrl();
    }

    public getHardwareOnlyHwCatalogUrl(): string {
        return this.dataService.getHardwareOnlyHwCatalogUrl();
    }
}
