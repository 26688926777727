import {IDataService} from './IDataService';
import {TopOfferPageValue} from '../domain/TopOfferPageValue';
import {Content, ICallService, ILogger, ILoggerService, IPageValueProvider} from "@shop/common";
import {ConfigurationValue} from '../domain/ConfigurationValue';
import {HardwareValue} from '../domain/hardware/HardwareValue';
import {PriceValue} from '../domain/PriceValue';
import {StockHintValue} from '../domain/hardware/StockHintValue';
import {TariffValue} from '../domain/tariff/TariffValue';
import {HardwareContentsValue} from '../domain/hardware/HardwareContentsValue';
import {ColorOptionValue} from '../domain/hardware/ColorOptionValue';
import {StorageOptionValue} from '../domain/hardware/StorageOptionValue';
import {TariffContentsValue} from '../domain/tariff/TariffContentsValue';
import {TechnicalDetailsGroupValue} from '../domain/hardware/TechnicalDetailsGroupValue';
import {PackGroupValue} from '../domain/tariff/PackGroupValue';
import {MediaGalleryValue} from '../domain/hardware/MediaGalleryValue';
import {MediaGalleryItemType} from '../domain/hardware/MediaGalleryItemType';
import {MediaGalleryItemValue} from '../domain/hardware/MediaGalleryItemValue';
import {StringTechnicalDetailsValue} from '../domain/hardware/StringTechnicalDetailsValue';
import {AbstractTechnicalDetailsValue} from '../domain/hardware/AbstractTechnicalDetailsValue';
import {EmptyTechnicalDetailsValue} from '../domain/hardware/EmptyTechnicalDetailsValue';
import {ListElementValue} from '../domain/ListElementValue';
import {CriteriaGroupValue} from "../domain/criteria/CriteriaGroupValue";
import {CriteriaGroupType} from "../domain/criteria/CriteriaGroupType";
import {CriteriaValue} from "../domain/criteria/CriteriaValue";
import {TargetGroupSwitcherValue} from "../domain/TargetGroupSwitcherValue";
import _ = require('underscore');

export class DataService implements IDataService {

    static $inject:string[] = [
        'pageValueProvider',
        'callService',
        'loggerService'
    ];

    private configurationForListElementId:{[id:string]:ConfigurationValue} = {};
    private logger:ILogger;

    constructor(private pageValueProvider:IPageValueProvider<TopOfferPageValue>,
                private callService:ICallService,
                loggerService:ILoggerService) {
        this.logger = loggerService.create('DataService');
    }

    public $onInit() {

        //get all list elements...
        var listElements = []
            .concat(this.getPageValue().postpaidListElements)
            .concat(this.getPageValue().prepaidListElements)
            .concat(this.getPageValue().hardwareOnlyListElements);

        this.configurationForListElementId = listElements.reduce((map, listElement:ListElementValue) => {
            map[listElement.id] = this.getPageValue().configurations[listElement.initialConfigurationId];
            return map;
        }, {});
    }

    private getCriteriaValues():CriteriaGroupValue[]{
        return this.getPageValue().criteriaGroups;
    }

    public getManufacturerCriteriaGroup():{[csSclass:string]:CriteriaValue} {
        var criteriaValues = this.getCriteriaValues()
            .filter((criteriaGroup:CriteriaGroupValue) => criteriaGroup.criteriaGroupType === CriteriaGroupType.MANUFACTURER)[0]
            .criterias;

        return _.reduce(criteriaValues,
                (map:{[csSclass:string]:CriteriaValue} , criteria:CriteriaValue) => {
                    map[criteria.title.toLocaleLowerCase()] = criteria;
                    return map;
        }, <{[csSclass:string]:CriteriaValue}>{});
    }

    public getAllCriteriaGroup():CriteriaGroupValue[] {
        return this.getCriteriaValues()
            .filter((criteriaGroup:CriteriaGroupValue) => criteriaGroup.criteriaGroupType !== CriteriaGroupType.MANUFACTURER)
    }

    public submitSelected(id:string):ng.IPromise<any> {
        var promise:ng.IPromise<any> = this.callService.handleCall(this.getConfiguration(id).calls.submitCall);
        return promise;
    }

    public navigateToAllDevices():void {
        this.callService.handleCall(this.getPageValue().calls.wwwCall);
    }

    public getConfiguration(listElementId:string):ConfigurationValue {
        return this.configurationForListElementId[listElementId];
    }

    public setConfiguration(listElementId:string, configuration:ConfigurationValue):void {
        this.configurationForListElementId[listElementId] = configuration;
    }

    public getHardware(id:string):HardwareValue {
        return this.getConfiguration(id).hardware;
    }

    /**
     * transforms a tech data TechnicalDetailsGroupValues so that empty or incomplete tech data entries are omitted
     */
    public getTransformedHardwareTechnicalDetailGroups(id:string):TechnicalDetailsGroupValue[] {

        var omitEmptyTechnicalDetailsGroups = function (categoryItem:TechnicalDetailsGroupValue):boolean {
            // omit TechnicalDetailsGroups with no entries
            return (categoryItem.technicalDetails.length > 0);
        };

        var omitEmptyTechnicalDetails = function (technicalDetailsGroup:TechnicalDetailsGroupValue):TechnicalDetailsGroupValue {
            // omit EmptyTechnicalDetailsValue instances
            technicalDetailsGroup.technicalDetails =
                _(technicalDetailsGroup.technicalDetails).filter((technicalDetailsEntry:AbstractTechnicalDetailsValue) => {
                    return !(technicalDetailsEntry instanceof EmptyTechnicalDetailsValue);
                });
            return technicalDetailsGroup;
        };

        var omitEmptyStringTechnicalDetails = function (technicalDetailsGroup:TechnicalDetailsGroupValue):TechnicalDetailsGroupValue {
            // omit StringTechnicalDetailsValue instances with an empty or missing keyValue
            technicalDetailsGroup.technicalDetails =
                _(technicalDetailsGroup.technicalDetails).filter((technicalDetailsEntry:AbstractTechnicalDetailsValue) => {
                    return !(
                        (technicalDetailsEntry instanceof StringTechnicalDetailsValue) && (
                            (<StringTechnicalDetailsValue>technicalDetailsEntry).keyValue === '' ||
                            (<StringTechnicalDetailsValue>technicalDetailsEntry).keyValue == null // non-strict equality check indended here
                        )
                    );
                });
            return technicalDetailsGroup;
        };

        return _(this.getHardware(id).technicalDetailGroups).chain()
            .map(omitEmptyTechnicalDetails)
            .map(omitEmptyStringTechnicalDetails)
            .filter(omitEmptyTechnicalDetailsGroups)
            .value();
    }

    public getHardwareName(id:string):string {
        return this.getHardwareContents(id).name;
    }

    public getHardwareOverviewItems(id:string):string[] {
        var contents = this.getHardwareContents(id);
        return [contents.overviewOne, contents.overviewTwo, contents.overviewThree, contents.overviewFour];
    }

    public getMediaGallery(id:string):MediaGalleryValue {
        return this.getHardware(id).mediaGallery;
    }

    public getInitialMediaGalleryItemType(id:string):MediaGalleryItemType {
        var firstItemWithContent:MediaGalleryItemValue = _.find(this.getMediaGallery(id).items, (item) => {
            return item.contentAvailable;
        });


        return firstItemWithContent.type;
    }

    public isMediaGalleryAvailable(id:string):boolean {
        return _.filter(this.getMediaGallery(id).items, (item) => {
                return item.contentAvailable;
            }).length > 0;
    }

    public getPrices(id:string):PriceValue {
        return this.getConfiguration(id).price;
    }

    public isSubmitEnabled(id:string):boolean {
        return this.getConfiguration(id).submitEnabled;
    }

    public getFootnotes(id:string):string {
        let tariff = this.getConfiguration(id).tariff;
        if (!tariff) {
            return null;
        }
        return tariff.contents.footnotes;
    }

    public getStockHint(id:string):StockHintValue {
        return this.getHardware(id).stockHint;
    }

    public getTariff(id:string):TariffValue {
        return this.getConfiguration(id).tariff;
    }

    public getHardwareContents(id:string):HardwareContentsValue {
        return this.getHardware(id).contents;
    }

    public getHardwareColors(id:string):ColorOptionValue[] {
        return this.getHardware(id).colorOptions;
    }

    public getHardwareStorages(id:string):StorageOptionValue[] {
        return this.getHardware(id).storageOptions;
    }

    public getTariffContents(id:string):TariffContentsValue {
        const tariff:TariffValue = this.getTariff(id);
        if(tariff !== null) {
            return tariff.contents;
        }
        return null;
    }

    public getTariffName(id:string):string {
        return this.getTariffContents(id).name;
    }

    public getTariffOverviewItems(id:string):string[] {
        var contents = this.getTariffContents(id);
        return [contents.overviewOne, contents.overviewTwo, contents.overviewThree, contents.overviewFour];
    }

    public getTariffConditions(id:string):string {
        return this.getTariffContents(id).conditions;
    }

    public getTariffNumberPorting(id:string):string {
        return this.getTariffContents(id).numberPorting;
    }

    public getTariffFeatures(id:string):string[] {
        var contents:TariffContentsValue = this.getTariffContents(id);
        return [contents.dataVolume, contents.bandwidth, contents.phoneFlat, contents.smsFlat, contents.roaming];
    }

    public getTariffDataVolume(id:string):any{
        let contents:TariffContentsValue = this.getTariffContents(id);
        return contents.dataVolume;
    }

    public getSeoHeadlineText():string {
        return this.getPageValue().seoHeadlineText;
    }

    public getSeoTopTeaserText():string {
        return this.getPageValue().seoTopTeaserText;
    }

    public getSeoBottomTeaserText():string {
        return this.getPageValue().seoBottomTeaserText;
    }

    private getPageValue():TopOfferPageValue {
        return this.pageValueProvider.getPageValue();
    }


    public getPackGroups(id:string):PackGroupValue[] {
        const tariff: TariffValue  = this.getTariff(id);
        if(tariff !== null) {
            return tariff.packGroups;
        }
        return [];
    }

    public getPriceInteger(price:number):string {
        return Math.floor(price).toString();
    }

    //TODO PTI-543 replace this logic with Money type logic
    public getPriceDecimal(price:number):string {
        return (price === 0) ? "00" : Math.round(price * 100).toString().substr(-2);
    }

    public getRatingStars(id:string):string {
        return this.getHardwareContents(id).ratingStars;
    }

    public getRatingReviews(id:string):Content {
        return this.getHardwareContents(id).ratingReviews;
    }

    public getExternalId(id:string):string {
        return this.getHardwareContents(id).externalId;
    }

    public getTargetGroupSwitcherValue(): TargetGroupSwitcherValue {
        return this.getPageValue().targetGroupSwitcherValue;
    }

    public switchTargetGroup(): void {
        // TODO: OSD-2609 fire a second call for logging?
        this.callService.handleCall(this.getPageValue().targetGroupSwitcherValue.call);
    }

    public getNoOffersAvailableText(): string {
        return this.getPageValue().noOffersAvailableText;
    }

    public getBundleHwCatalogUrl(): string {
        if (null != this.getPageValue().topOfferSelectionTypeUrl) {
            return this.getPageValue().topOfferSelectionTypeUrl.bundleHwCatalogUrl;
        }
        return null;
    }

    public getHardwareOnlyHwCatalogUrl(): string {
        if (null != this.getPageValue().topOfferSelectionTypeUrl) {
            return this.getPageValue().topOfferSelectionTypeUrl.hardwareOnlyHwCatalogUrl;
        }
        return null;
    }

    public getSchemaData(id:string):any {
        return this.getConfiguration(id).schemaDataElements;
    }

}
