import {IOffersComponentService} from "../IOffersComponentService";

export class CoOfferCartDisplayController {

    static $inject:string[] = ['offersComponentService'];

    private id:string;

    constructor(private offersComponentService:IOffersComponentService) {
    }

    public isHardwareOnlySelected():boolean {
        return this.offersComponentService.isHardwareOnlySelected();
    }

    public submitSelected():void {
        this.offersComponentService.submitSelected(this.id);
    }

    public isSubmitPossible():boolean {
        return this.offersComponentService.isSubmitPossible(this.id);
    }

    public getHardwareName():string {
        return this.offersComponentService.getHardwareName(this.id);
    }

    public getHardwarePdpLink():string {
        return this.offersComponentService.getHardwarePdpLink(this.id);
    }
}
