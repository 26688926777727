import {CoTariffSwitcherController} from "./coTariffSwitcherController";
import coTariffSwitcherTemplateHtml from "./coTariffSwitcherTemplate.html";

export class CoTariffSwitcher implements ng.IComponentOptions {
  bindings:any = {
    entries:'<coTariffSwitcherEntries',
    selectedEntry:"=selectedEntry"
  };
  controller:any = CoTariffSwitcherController;
  template:string = coTariffSwitcherTemplateHtml;
}