import {MetaInfo, enumMetaInfo} from "@shop/common";
import _ = require("underscore");
export class MediaGalleryItemType {

    static META_INFO:MetaInfo = enumMetaInfo('topOffer:MediaGalleryItemType');

    private static _values:{ [s:string]:MediaGalleryItemType; } = {};

    static forName(obj):MediaGalleryItemType {
        // @ts-ignore
        argsContract(arguments, 'str');
        if (obj === null) {
            return null;
        }
        return MediaGalleryItemType._values[obj];
    }

    static values():MediaGalleryItemType[] {
        return _.values(MediaGalleryItemType._values);
    }

    static PICTURE:MediaGalleryItemType = new MediaGalleryItemType("PICTURE");
    static VIDEO:MediaGalleryItemType = new MediaGalleryItemType("VIDEO");
    static THREE_D:MediaGalleryItemType = new MediaGalleryItemType("THREE_D");

    constructor(public  name:string) {
        // @ts-ignore
        argsContract(arguments, 'str');
        MediaGalleryItemType._values[name] = this;
    }

}
