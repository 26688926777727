import {MetaInfo, valueMetaInfo} from "@shop/common";
import {TariffOptionContentsValue} from "./TariffOptionContentsValue";
import {TariffOptionCalls} from "./TariffOptionCalls";
export class TariffOptionValue {

    static META_INFO:MetaInfo = valueMetaInfo('topOffer:TariffOptionValue', {
        id: 'string',
        contents: 'TariffOptionContentsValue',
        selected: 'boolean',
        calls: 'TariffOptionCalls'
    });

    id:string;
    contents:TariffOptionContentsValue;
    selected:boolean;
    calls:TariffOptionCalls;
}
