import IAngularEvent = angular.IAngularEvent;
import {MediaGalleryItemType} from "../../../domain/hardware/MediaGalleryItemType";
import {MediaGalleryValue} from "../../../domain/hardware/MediaGalleryValue";
import {MediaGalleryItemValue} from "../../../domain/hardware/MediaGalleryItemValue";
import {IOffersComponentService} from "../IOffersComponentService";

export class HardwareMediaController {
    static $inject:string[] = ['offersComponentService'];

    private id:string;

    constructor(public offersComponentService:IOffersComponentService) {
    }

    public getTabName(type:MediaGalleryItemType):string {
        switch (type) {
            case MediaGalleryItemType.PICTURE:
                return 'Galerie';
            case MediaGalleryItemType.VIDEO:
                return 'Video';
            case MediaGalleryItemType.THREE_D:
                return '3D';
            default:
                return '';
        }
    }

    public isShowMediaGallery():boolean {
        return this.offersComponentService.isShowMediaGallery();
    }

    public closeMediaGallery():void {
        this.offersComponentService.closeMediaGallery();
    }

    public getMediaGallery():MediaGalleryValue {
        return this.offersComponentService.getMediaGallery();
    }

    public isMediaGalleryTypeActive(type:MediaGalleryItemType):boolean {
        return type === this.getActiveMediaGalleryItemType();
    }

    public getActiveMediaGalleryItemType():MediaGalleryItemType {
        return this.offersComponentService.getActiveMediaGalleryItemType();
    }

    public isMediaGalleryTabShown(item:MediaGalleryItemValue):boolean {
        return item.contentAvailable;
    }

    public setActiveMediaGalleryItemType(type:MediaGalleryItemType, $event:IAngularEvent):void {
        $event.preventDefault();
        this.offersComponentService.setActiveMediaGalleryItemType(type);
    }
}

