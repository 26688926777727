import {MetaInfo, valueMetaInfo} from "@shop/common";
import {StockHintContentsValue} from "./StockHintContentsValue";
import {StockHintType} from "./StockHintType";
export class StockHintValue {
    static META_INFO:MetaInfo = valueMetaInfo('topOffer:StockHintValue', {
        contents: 'StockHintContentsValue',
        hintType: 'StockHintType'
    });

    contents:StockHintContentsValue;
    hintType:StockHintType;

}
