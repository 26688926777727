import {IOffersComponentService} from "../IOffersComponentService";
export class OfferHardwareController {
    static $inject:string[] = ['offersComponentService'];

    private id:string;

    constructor(private offersComponentService:IOffersComponentService) {
    }

    public getHardwareName():string {
        return this.offersComponentService.getHardwareName(this.id);
    }

    public getHardwareImageLink():string {
        return this.offersComponentService.getHardwareImageLink(this.id);
    }

    public openMediaGallery():void {
        this.offersComponentService.openMediaGallery(this.id);
    }

    public isMediaGalleryAvailable():boolean {
        return this.offersComponentService.isMediaGalleryAvailable(this.id);
    }
}
