import { hardwareTileController } from './hardwareTileController';
import  hardwareTileTemplateHtml  from "./hardwareTileTemplate.html";

export class HardwareTile implements ng.IComponentOptions {
    bindings:any = {
        id: '=coOfferDetailsDescriptionId'
    };
    controller:any = hardwareTileController;
    template:string = hardwareTileTemplateHtml;
}
