import {MetaInfo, valueMetaInfo} from "@shop/common";
import {SortingContentsValue} from "./SortingContentsValue";
export class SortingValue {

    static META_INFO:MetaInfo = valueMetaInfo('topOffer:SortingValue', {
        contents: 'SortingContentsValue',
        selected: 'boolean'
    });

    contents:SortingContentsValue;
    selected:boolean;

}
    