import coTargetGroupSwitcherHtml from "./coTargetGroupSwitcher.html";

import {TargetGroupSwitcherController} from "./TargetGroupSwitcherController";

export class CoTargetGroupSwitcher implements ng.IComponentOptions {
        controller:any = TargetGroupSwitcherController;
        template:string = coTargetGroupSwitcherHtml;
}


