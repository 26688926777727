import {OfferPricesController} from "./OfferPricesController";
import  coOfferPricesTemplateHtml  from "./coOfferPricesTemplate.html";

export class CoOfferPrices implements ng.IComponentOptions {
    bindings:any = {
        id: '=coOfferPricesId'
    };
    controller:any = OfferPricesController;
    template:string = coOfferPricesTemplateHtml;
}
