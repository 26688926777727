import {MetaInfo, valueMetaInfo} from "@shop/common";
import {HardwareValue} from "./hardware/HardwareValue";
import {TariffValue} from "./tariff/TariffValue";
import {TariffOptionValue} from "./TariffOptionValue";
import {PriceValue} from "./PriceValue";
import {ConfigurationCalls} from "./ConfigurationCalls";
import {EcommerceProductValue} from "./EcommerceProductValue";
export class ConfigurationValue {

    static META_INFO:MetaInfo = valueMetaInfo('topOffer:ConfigurationValue', {
        id:'string',
        hardware: 'HardwareValue',
        tariff: 'TariffValue | null' ,
        tariffOptionEntries: '[TariffOptionValue] | null',
        price: 'PriceValue',
        calls: 'ConfigurationCalls',
        submitEnabled: 'boolean',
        ecommerceProductValue: 'EcommerceProductValue | null',
        schemaDataElements: 'any'
    });

    id:string;
    hardware:HardwareValue;
    tariff:TariffValue;
    tariffOptionEntries:TariffOptionValue[];
    price:PriceValue;
    calls:ConfigurationCalls;
    submitEnabled: boolean;
    ecommerceProductValue:EcommerceProductValue;
    schemaDataElements: any;
}

