import {Call, MetaInfo, valueMetaInfo} from "@shop/common";

export class TargetGroupSwitcherValue {

    static META_INFO:MetaInfo = valueMetaInfo('topOffer:TargetGroupSwitcherValue', {
        call: 'Call',
        bannerMarkup: 'string',
        targetGroup: 'string',
        visible: 'boolean'
    });

    call:Call<any>;
    bannerMarkup: string;
    targetGroup: string;
    visible: boolean;
}