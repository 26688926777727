import {IProductDetailsComponentService} from "../IProductDetailsComponentService";
import {PackGroupValue} from "../../../domain/tariff/PackGroupValue";
import IAngularEvent = angular.IAngularEvent;
import _ = require("underscore");

export class TariffDetailsController {
    static $inject:string[] = ['productDetailsComponentService'];

    private id:string;

    constructor(private productDetailsComponentService:IProductDetailsComponentService) {
    }

    public getTariffName():string {
        return this.productDetailsComponentService.getTariffName(this.id);
    }

    public getTariffOverviewItems():string[] {
        return this.productDetailsComponentService.getTariffOverviewItems(this.id);
    }

    public getTariffConditions():string {
        return this.productDetailsComponentService.getTariffConditions(this.id);
    }

    public getTariffNumberPorting():string {
        return this.productDetailsComponentService.getTariffNumberPorting(this.id);
    }

    public isTariffNumberPortingEmpty():boolean {
        return _.isEmpty(this.getTariffNumberPorting());
    }

    public isTariffConditionEmpty():boolean {
        return _.isEmpty(this.getTariffConditions());
    }

    public isTariffOverviewItemsEmpty():boolean {
        return _.isEmpty(this.getTariffOverviewItems());
    }

    public getPackGroups():PackGroupValue[] {
        return this.productDetailsComponentService.getPackGroups(this.id);
    }

    public getPackGroupName(packGroup:PackGroupValue):string {
        return packGroup.contents.title;
    }

    public isAccordionEntryOpen(entryId:string):boolean {
        return this.productDetailsComponentService.isTariffAccordionEntryOpen(this.id, entryId);
    }

    public toggleAccordionEntry(entryId:string, $event:IAngularEvent):void {
        $event.preventDefault();
        this.productDetailsComponentService.toggleTariffAccordionEntry(this.id, entryId);
    }
}
