import {MetaInfo, enumMetaInfo} from "@shop/common";
export class StockHintType {

    static META_INFO:MetaInfo = enumMetaInfo('topOffer:StockHintType');

    static ON_STOCK:StockHintType = new StockHintType('ON_STOCK');
    static ON_STOCK_LIMITED_AVAILABILITY:StockHintType = new StockHintType('ON_STOCK_LIMITED_AVAILABILITY');
    static VIRTUAL_STOCK:StockHintType = new StockHintType('VIRTUAL_STOCK');
    static OUT_OF_STOCK:StockHintType = new StockHintType('OUT_OF_STOCK');

    constructor(public name:string) {
        argsContract(arguments, 'str');
    }
}
