import {valueMetaInfo} from "@shop/common";
import {AbstractTechnicalDetailsValue} from "./AbstractTechnicalDetailsValue";

export class StringTechnicalDetailsValue extends AbstractTechnicalDetailsValue {

    static META_INFO = valueMetaInfo('topOffer:StringTechnicalDetailsValue', {
        keyName: 'string | null',
        keyValue: 'string | null'
    });

    keyValue:string;

    public isBooleanTypedField():boolean {
        return false;
    }
}
