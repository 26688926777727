import {ITabsComponentService} from "./ITabsComponentService";
import {IDataService} from "../../services/IDataService";
import {CriteriaValue} from "../../domain/criteria/CriteriaValue";
import {IListElementService} from "../../services/IListElementService";
import {ITopOfferFeatureToggleService} from "../../services/ITopOfferFeatureToggleService";
export class TabsComponentService implements ITabsComponentService {
    static $inject:string[] = ["dataService", "listElementService", "topOfferFeatureToggleService"];

    constructor(private dataService:IDataService,
                private listElementService:IListElementService,
                private topOfferFeatureToggleService:ITopOfferFeatureToggleService) {}

    public isFilterDisabled():boolean {
        return !this.topOfferFeatureToggleService.isFiltersEnabled();
    }

    public switchManufacturer(criteriaKeyValue:string):void {
        this.listElementService.switchManufacturer(criteriaKeyValue);
    }

    public getManufacturerCriteriaValues():{[csSclass:string]:CriteriaValue} {
        return this.dataService.getManufacturerCriteriaGroup();
    }

    public showAllManufacturer():void {
        this.listElementService.showAllManufacturer();
    }

    public getSeoHeadlineText():string {
        return this.dataService.getSeoHeadlineText();
    }

    public getSeoTopTeaserText():string {
        return this.dataService.getSeoTopTeaserText();
    }
}
