import {ITopOfferFeatureToggleService} from "./ITopOfferFeatureToggleService";
import {IPageValueProvider} from "@shop/common";
import {TopOfferPageValue} from "../domain/TopOfferPageValue";

export class TopOfferFeatureToggleService implements ITopOfferFeatureToggleService {

    static $inject:string[] = ['pageValueProvider'];

    constructor(private pageValueProvider:IPageValueProvider<TopOfferPageValue>) {
    }

    public isFiltersEnabled():boolean {
        return this.pageValueProvider.getPageValue().featureTogglesValue.filtersEnabled;
    };

    public isTotalPriceDisplayEnabled():boolean {
        return this.pageValueProvider.getPageValue().featureTogglesValue.totalPriceDisplayEnabled;
    };

    public isTariffOptionSwitchEnabled():boolean {
        return this.pageValueProvider.getPageValue().featureTogglesValue.tariffOptionSwitchEnabled;
    };
    public isNewBlauPlpEnabled():boolean {
        return this.pageValueProvider.getPageValue().featureTogglesValue.newBlauPlpEnabled;
    };

}
