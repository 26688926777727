import {IUiStateService} from "./IUiStateService";
import {MediaGalleryItemType} from "../domain/hardware/MediaGalleryItemType";

export class UiStateService implements IUiStateService {
    private uiState:UIState;

    constructor() {
        this.uiState = new UIState();
    }

    public isShowMediaGallery():boolean {
        return this.getUIState().showMediaGallery;
    }

    public openMediaGallery(id:string):void {
        this.getUIState().activeMediaGalleryId = id;
        this.getUIState().showMediaGallery = true;
    }

    public closeMediaGallery():void {
        this.getUIState().showMediaGallery = false;
        this.getUIState().activeMediaGalleryId = '';
    }

    public getActiveMediaGalleryItemType():MediaGalleryItemType {
        if(this.getUIState().activeMediaGalleryItemTypeById[this.getActiveMediaGalleryId()]===undefined){
            return null;
        }
        else {
            return this.getUIState().activeMediaGalleryItemTypeById[this.getActiveMediaGalleryId()];
        }
    }

    public setActiveMediaGalleryItemType(type:MediaGalleryItemType) {

        this.getUIState().activeMediaGalleryItemTypeById[this.getActiveMediaGalleryId()] = type;
    }

    public getActiveMediaGalleryId():string {
        return this.uiState.activeMediaGalleryId;
    }

    public isAllDetailsOpen(id:string):boolean {
        return this.getTileState(id).allDetailsOpen;
    }

    public toggleAllDetails(id:string):void {
        this.getTileState(id).allDetailsOpen = !this.getTileState(id).allDetailsOpen;
    }

    public isTariffAccordionEntryOpen(id:string, entryId:string):boolean {
        argsContract(arguments, 'string, string');
        return this.getTileState(id).openTariffAccordionEntryId === entryId;
    }

    public isHardwareAccordionEntryOpen(id:string, entryId:string):boolean {
        argsContract(arguments, 'string, string');
        return this.getTileState(id).openHardwareAccordionEntryId === entryId;
    }

    public toggleTariffAccordionEntry(id:string, entryId:string):void {
        argsContract(arguments, 'string, string');
        this.getTileState(id).openTariffAccordionEntryId = this.getTileState(id).openTariffAccordionEntryId === entryId ? '' : entryId;
    }

    public toggleHardwareAccordionEntry(id:string, entryId:string):void {
        argsContract(arguments, 'string, string');
        this.getTileState(id).openHardwareAccordionEntryId = this.getTileState(id).openHardwareAccordionEntryId === entryId ? '' : entryId;
    }

    public getActiveReviewsModalId():string {
        return this.getUIState().activeReviewModalId;
    }

    public openReviewsModal(id:string) {
        this.getUIState().activeReviewModalId = id;
        this.getUIState().showReviewsModal = true;
    }

    public closeReviewsModal() {
        this.getUIState().showReviewsModal = false;
        this.getUIState().activeReviewModalId = '';
    }

    public isReviewsModalOpen() {
        return this.getUIState().showReviewsModal;
    }

    private getUIState():UIState {
        return this.uiState;
    }

    private getTileState(id:string):TileState {
        if (!this.getUIState().tileStates[id]) {
            this.getUIState().tileStates[id] = new TileState();
        }
        return this.getUIState().tileStates[id];
    }

    public getSubmitInProgress():boolean {
        return this.getUIState().submitInProgress;
    }

    public setSubmitInProgress(submitInProgress:boolean):void {
        this.getUIState().submitInProgress = submitInProgress;
    }

    public setFilterStatus(filterclicked:boolean):void {
        this.getUIState().filterClicked = filterclicked;
    }

    public getFilterStatus():boolean {
        return this.getUIState().filterClicked;
    }
}

class UIState {
    showMediaGallery:boolean = false;
    activeMediaGalleryId:string = '';
    showReviewsModal:boolean = false;
    activeReviewModalId:string = '';
    tileStates:TileState[] = [];
    //container object for mapping MediaGalleryItemType objects on activeMediaGalleryId strings
    activeMediaGalleryItemTypeById:{} = {};
    submitInProgress:boolean = false;
    filterClicked: boolean = false;
}

class TileState {
    allDetailsOpen:boolean;
    openTariffAccordionEntryId:string;
    openHardwareAccordionEntryId:string;

    constructor() {
        this.allDetailsOpen = false;
        // the entries which should be opened by default
        this.openTariffAccordionEntryId = 'tariff-overview';
        this.openHardwareAccordionEntryId = 'hardware-overview';
    }
}