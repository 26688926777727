import {valueMetaInfo, MetaInfo} from "@shop/common";
import {CriteriaValue} from "./CriteriaValue";
import {LogicType} from "./LogicType";
import {CriteriaGroupType} from "./CriteriaGroupType";
import _ = require("underscore");
export class CriteriaGroupValue {

    static META_INFO:MetaInfo = valueMetaInfo('topOffer:CriteriaGroupValue', {
        title: 'string',
        criteriaGroupType: 'CriteriaGroupType | undefined',
        criterias: '[CriteriaValue]',
        logicType: 'LogicType | undefined'
    });

    title:string;
    criteriaGroupType:CriteriaGroupType;
    criterias:CriteriaValue[];
    logicType:LogicType;

    getSelectedValues():string[] {
        var selected = _.filter(this.criterias,
            (criteria) => criteria.selected
        );

        return _.map(
            selected,
            (criteria) => criteria.criteriaKeyValue
        );
    }

    resetSelectedValues():void {
        _.each(this.criterias, (criteria:CriteriaValue) => {
            criteria.selected = false;
        });
    }
}
