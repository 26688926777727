import {MetaInfo, valueMetaInfo} from "@shop/common";
export class PackContentsValue {

    static META_INFO:MetaInfo = valueMetaInfo('topOffer:PackContentsValue', {
        name: 'string',
        price: 'number',
        description: 'string | null',
        details: 'string | null'
    });

    name:string;
    price:number;
    description:string;
    details:string;
}
