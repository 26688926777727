import {enumMetaInfo} from "@shop/common";

export class TechnicalDetailsGroupType {

    static META_INFO = enumMetaInfo('topOffer:TechnicalDetailsGroupType');

    static CAMERA = new TechnicalDetailsGroupType("CAMERA");
    static DISPLAY = new TechnicalDetailsGroupType("DISPLAY");
    static DEVICE = new TechnicalDetailsGroupType("DEVICE");
    static MEMORY = new TechnicalDetailsGroupType("MEMORY");

    constructor(public name:string) {
        argsContract(arguments, 'str');
    }
}
