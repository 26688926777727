import {IDataService} from "../../services/IDataService";
import {ITargetGroupSwitcherComponentService} from "./ITargetGroupSwitcherComponentService";
import {TargetGroupSwitcherValue} from "../../domain/TargetGroupSwitcherValue";

export class TargetGroupSwitcherComponentService implements ITargetGroupSwitcherComponentService {
    static $inject:string[] = ["dataService"];

    constructor(private dataService:IDataService) {}

    public getTargetGroupSwitcherValue(): TargetGroupSwitcherValue {
        return this.dataService.getTargetGroupSwitcherValue();
    }

    public switchTargetGroup(): void {
        return this.dataService.switchTargetGroup();
    }
}
