import {MetaInfo, valueMetaInfo} from "@shop/common";
import {CriteriaContentsValue} from "./CriteriaContentsValue";
export class CriteriaValue {

    static META_INFO:MetaInfo = valueMetaInfo('topOffer:CriteriaValue', {
        title:'string',
        criteriaKeyValue:'string',
        selected: 'boolean'
    });

    title:string;
    criteriaKeyValue:string;
    selected:boolean;

    toggle():void {
        this.selected = !this.selected;
    }

}
