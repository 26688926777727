import {MetaInfo, valueMetaInfo} from "@shop/common";

export class LoginStatusTrackingValue {

    static META_INFO: MetaInfo = valueMetaInfo('topOffer:LoginStatusTrackingValue', {
        cust_cookie_value: 'string',
        logged_in: 'boolean',
        user_type: 'string',
    });

    cust_cookie_value: string;
    logged_in: boolean;
    user_type: string;
}