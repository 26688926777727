import {IOffersComponentService} from "../IOffersComponentService";
import {PriceValue} from "../../../domain/PriceValue";

export class OfferPricesController {

    static $inject:string[] = ['offersComponentService'];

    private id:string;

    constructor(private offersComponentService:IOffersComponentService) {
    }

    public getPrices():PriceValue {
        return this.offersComponentService.getPrices(this.id);
    }

    public getFootnotes():string {
        return this.offersComponentService.getFootnotes(this.id);
    }

    public isPostpaidSelected():boolean {
        return this.offersComponentService.isPostpaidSelected();
    }

    public isPrepaidSelected():boolean {
        return this.offersComponentService.isPrepaidSelected();
    }

    public isHardwareOnlySelected():boolean {
        return this.offersComponentService.isHardwareOnlySelected();
    }
}