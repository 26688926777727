import {IListElementService} from "./IListElementService";
import {CriteriaValue} from "../domain/criteria/CriteriaValue";
import {ListElementValue} from "../domain/ListElementValue";
import {LogicType} from "../domain/criteria/LogicType";
import {CriteriaGroupValue} from "../domain/criteria/CriteriaGroupValue";
import {TariffSelectionType} from "../domain/TariffSelectionType";
import {TopOfferPageValue} from "../domain/TopOfferPageValue";
import {ILoggerService} from "@shop/common";
import {IPageValueProvider} from "@shop/common";
import {ILogger} from "@shop/common";
import {CriteriaGroupType} from "../domain/criteria/CriteriaGroupType";
import {SortingEntry} from "./IListElementService";
import {SortingValue} from "../domain/SortingValue";
import _ = require("underscore");
export class ListElementService implements IListElementService {

    static $inject:string[] = [
        'pageValueProvider',
        'loggerService'
    ];

    private static PAGE_SIZE = 15;

    private prepaidListElements:ListElementValue[] = [];
    private postpaidListElements:ListElementValue[] = [];
    private hardwareOnlyListElements:ListElementValue[] = [];
    private visibleListElements:ListElementValue[] = [];
    private sortingEntries:SortingEntry[] = [];
    private selectedSortingEntry:SortingEntry;

    private criteriaValues:CriteriaGroupValue[] = [];
    private tariffSelectionType:TariffSelectionType;

    private logger:ILogger;

    private elementCountToShow:number;
    private moreRowsAvailable:boolean = true;
    private currentCriteriaValue:string = "";

    constructor(private pageValueProvider:IPageValueProvider<TopOfferPageValue>,
                private loggerService:ILoggerService){
        this.logger = loggerService.create('DataService');
    }

    public $onInit() {
        var pageValue = this.getPageValue();
        this.prepaidListElements = pageValue.prepaidListElements;
        this.postpaidListElements = pageValue.postpaidListElements;
        this.hardwareOnlyListElements = pageValue.hardwareOnlyListElements;
        this.tariffSelectionType = pageValue.tariffSelection;
        this.criteriaValues = pageValue.criteriaGroups;
        this.sortingEntries = _(pageValue.sortingValues)
            .chain()
            .map((sortingValue:SortingValue) => {
                return [
                    new SortingEntry(sortingValue.contents.title, sortingValue.contents.name, true),
                    new SortingEntry(sortingValue.contents.title, sortingValue.contents.name, false)
                ]
            })
            .flatten()
            .value();

        this.selectedSortingEntry = this.sortingEntries[0];

        this.resetRowsToShow();
        this.updateListElementIds();
    }

    private getPageValue():TopOfferPageValue {
        return this.pageValueProvider.getPageValue();
    }


    public switchManufacturer(criteriaKeyValue:string):void {
        this.currentCriteriaValue = criteriaKeyValue;
        _(this.getCriteriaValues())
            .chain()
            .filter((group:CriteriaGroupValue) => CriteriaGroupType.MANUFACTURER === group.criteriaGroupType)
            .map((group:CriteriaGroupValue) => group.criterias)
            .flatten()
            .each((criteria:CriteriaValue) => criteria.selected = false)
            .filter((criteria:CriteriaValue) => criteria.criteriaKeyValue == criteriaKeyValue)
            .each((criteria:CriteriaValue) => criteria.selected = true);
        this.updateListElementIds();
    }

    public showAllManufacturer():void {
        _(this.getCriteriaValues())
            .chain()
            .filter((group:CriteriaGroupValue) => CriteriaGroupType.MANUFACTURER === group.criteriaGroupType)
            .map((group:CriteriaGroupValue) => group.criterias)
            .flatten()
            .each((criteria:CriteriaValue) => criteria.selected = false);
        this.updateListElementIds();
    }

    public toggleCriteriaValue(criteriaKeyValue:string):void{
        _(this.getCriteriaValues())
            .chain()
            .map((group:CriteriaGroupValue) => group.criterias)
            .flatten()
            .filter((criteria:CriteriaValue) => criteria.criteriaKeyValue == criteriaKeyValue)
            .each((criteria:CriteriaValue) => criteria.selected = !criteria.selected);

        this.updateListElementIds();
    }

    public resetCriteriaSelection():void {
        _.each(this.criteriaValues, (criteriaValue:CriteriaGroupValue) => {
           criteriaValue.resetSelectedValues();
        });
        this.setTariffSelection(this.getPageValue().tariffSelection);
    }

    public getVisibleListElements():ListElementValue[] {
        return this.visibleListElements;
    }

    public getVisibleListElementIds():string[] {
        return this.getVisibleListElements().map(listElementValue => listElementValue.id);
    }

    public getCriteriaValues():CriteriaGroupValue[] {
        return this.criteriaValues;
    }

    public getSortingEntries():SortingEntry[] {
        return this.sortingEntries;
    }

    public setSelectedSortingEntry(entry:SortingEntry) {
        this.selectedSortingEntry = entry;
        this.resetRowsToShow();
        this.updateListElementIds();
    }

    public getSelectedSortingEntry() {
        return this.selectedSortingEntry;
    }

    public getTariffSelection():TariffSelectionType {
        return this.tariffSelectionType;
    }

    public setTariffSelection(type:TariffSelectionType):void {
        this.tariffSelectionType = type;
        this.resetRowsToShow();
        this.updateListElementIds();
    }

    public isPostpaidSelected():boolean {
        return this.getTariffSelection() === TariffSelectionType.POSTPAID;
    }

    public isPrepaidSelected():boolean {
        return this.getTariffSelection() === TariffSelectionType.PREPAID;
    }

    public isHardwareOnlySelected():boolean {
        return this.getTariffSelection() === TariffSelectionType.HARDWARE_ONLY;
    }

    private updateListElementIds():void {

        this.filterListElementValues();
        var elements = this.getVisibleListElements();
        //if compareFunc != 0 && ( < 0, e1 ), else e2
        var sortedElements = elements.sort((e1:ListElementValue, e2:ListElementValue) => {

            var sortingOrder = this.selectedSortingEntry.descending ? 1 : -1;
            var sortingName = this.selectedSortingEntry.sortingName;
            // the higher the index, the lower the rank
            // e2 > e1 => result < 0 => e1 comes first
            return (e1.indexBySortingName[sortingName] - e2.indexBySortingName[sortingName]) * sortingOrder;
        });
        this.visibleListElements = sortedElements.slice(0, Math.min(this.elementCountToShow, sortedElements.length));

        this.moreRowsAvailable = this.visibleListElements.length < sortedElements.length;

        this.logger.debug("updateListElementIds", {
            originalListElements: elements,
            listElementIds: this.visibleListElements,
            moreRowsAvailable: this.moreRowsAvailable
        });
    }

    private getListElements():ListElementValue[] {
        switch (this.getTariffSelection()) {
            case TariffSelectionType.PREPAID:
                return this.prepaidListElements;
            case TariffSelectionType.HARDWARE_ONLY:
                return this.hardwareOnlyListElements;
            default:
                return this.postpaidListElements;
        }
    };

    private filterListElementValues():void {
        this.visibleListElements = _.filter(this.getListElements(), (listElementValue:ListElementValue) => {

                return _.every(this.getCriteriaValues(), (criteriaGroupValue:CriteriaGroupValue) => {
                        var selectedValues = _(criteriaGroupValue.criterias)
                            .filter((criteria) => criteria.selected)
                            .map((criteria) => criteria.criteriaKeyValue);

                        if (selectedValues.length === 0) {
                            return true;
                        }

                        if (criteriaGroupValue.logicType === LogicType.OR) {
                            // one or more criteria is matching
                            return _.intersection(selectedValues, listElementValue.criteriaValues).length !== 0;
                        }

                        if (criteriaGroupValue.logicType === LogicType.AND) {
                            // all criteria values must match
                            return _.difference(selectedValues, listElementValue.criteriaValues).length === 0;
                        }
                    });
            }
        );
    }

    private resetRowsToShow() {
        this.elementCountToShow = ListElementService.PAGE_SIZE;
        this.logger.debug("resetRowsToShow", { rowsToShow: this.elementCountToShow });
    }

    public showMoreRows():void {
        this.elementCountToShow += ListElementService.PAGE_SIZE;
        this.logger.debug("showMoreRows", { rowsToShow: this.elementCountToShow });
        this.updateListElementIds();
    }

    public moreRowsToShow():boolean {
        return this.moreRowsAvailable;
    }
    getCurrentCriteriaValue(): string {
        return this.currentCriteriaValue;
    }
}
