import {PriceContentsValue} from "../../../domain/PriceContentsValue";
import {PriceValue} from "../../../domain/PriceValue";
import {IOffersComponentService} from "../IOffersComponentService";

export class PriceSectionController {
    static $inject:string[] = ['offersComponentService'];

    public prices:PriceValue;
    public _type:string;
    public footnotes:string;


    constructor(private offersComponentService:IOffersComponentService) {
    }

    set type(v:string) {
        argsContract(arguments, 'string {{ oneOf("hardware", "tariff", "single") }}');
        this._type = v;
    }

    get type():string {
        return this._type;
    }

    public getPriceInteger():string {
        return this.offersComponentService.getPriceInteger(this.getPrice());
    }

    public getPriceDecimal():string {
        return this.offersComponentService.getPriceDecimal(this.getPrice());
    }

    private getPrice():number {
        switch (this.type) {
            case 'hardware':
                return this.prices.hardwarePriceAmount;
            case 'tariff':
                return this.prices.tariffPriceAmount;
            case 'single':
                return this.prices.singlePriceAmount;
            default:
                return 0;
        }
    }

    public isTotalPriceDisplayEnabled():boolean {
        return this.offersComponentService.isTotalPriceDisplayEnabled();
    }

    public getFootnotes(){
        return this.footnotes;
    }

    public getContractDurationDescription(){
        return this.getContents().contractDurationDescription;
    }

    public getFormattedNonRecurringAmount() {
        return this.offersComponentService.formatPrice(this.prices.nonRecurringAmount,true);
    }

    public getFormattedRecurringAmount() {
        return this.offersComponentService.formatPrice(this.getPrice(),false);
    }

    public getActivationFeeDescription(){
        if(this.getContents().activationFeeDescription.split('€')[0] === '+ 0 '){
            return "0,00 €"
        }else {
            return this.getContents().activationFeeDescription
        }
    }

    private getContents():PriceContentsValue {
        return this.prices.contents;
    }

}
