import {ConstantCall, IEventService, IPageValueProvider, ITrackingService, TrackingInfoValue} from "@shop/common";
import {TopOfferPageValue} from "../domain/TopOfferPageValue";
import {Events} from "./EventListenerRegistry";
import {EcommerceProductValue} from "../domain/EcommerceProductValue";

export class TopOfferTrackingService {

    static $inject: string[] = ['trackingService', 'pageValueProvider', 'eventService'];

    private readonly isUnifiedTrackingEnabled: boolean;

    constructor(private readonly trackingService: ITrackingService,
                private readonly pageValueProvider: IPageValueProvider<TopOfferPageValue>,
                private readonly eventService: IEventService) {
        this.isUnifiedTrackingEnabled = this.pageValueProvider.getPageValue().unifiedTrackingEnabled;
    }

    $onInit() {
        this.eventService.subscribe(Events.TOP_OFFER_TRACKING_INITIALIZED, () => {
            this.trackPage();
        })
    }

    public trackPage() {
        const data = this.isUnifiedTrackingEnabled ?
            {
                event : "init",
                page : {
                    content : undefined,
                    category : "top-offer",
                    productCategory : undefined,
                    application : "e-shop",
                    template: "whitelabel-top-offer-dev"
                }
            }
            :
            {
                pageCategory : "whitelabel-top-offer-dev",
                page : {}
            };

        this.track(data)
    }
    public buildTrackingObjectForProductImpression(ecommerceProductValues: EcommerceProductValue[], filterValue: string, hwIndex: number) {
        const map = ecommerceProductValues.map((ecommerceProductValue, index) => ({
            name: ecommerceProductValue.name,
            quantity: ecommerceProductValue.quantity,
            list: filterValue,
            ...ecommerceProductValue.attributes,
            position: (hwIndex+index+1).toString()
        }));

        const data =
            {
                event: 'productImpression',
                eCat: 'EEC',
                eAct: 'productImpression',
                eLab: '',
                nonInteraction: true,
                eshop_login_status: { ...this.pageValueProvider.getPageValue().loginStatusTrackingValue },
                ecommerce: {
                    impressions: [...map]
                }
            };
        this.track(data);
    }
    public trackProductClick(ecommerceProductValue: EcommerceProductValue, currentCriteriaValue:string, hwIndex:number) {
        const data = {
            event: 'productClick',
            eCat: 'EEC',
            eAct: 'productClick',
            eLab: '',
            nonInteraction: false,
            eshop_login_status: { ...this.pageValueProvider.getPageValue().loginStatusTrackingValue },
            ecommerce: {
                click: {
                    actionField: {'list': currentCriteriaValue},
                    products: [{
                        name: ecommerceProductValue.name,
                        quantity: ecommerceProductValue.quantity,
                        ...ecommerceProductValue.attributes,
                        position: (hwIndex+1).toString()
                    }]
                }
            }
        };
        this.track(data)
    }

    private track(data: any) {
        this.trackingService.track(new ConstantCall<TrackingInfoValue>({rawContent: JSON.stringify(data)}))
    }

}