import {MetaInfo, Call, valueMetaInfo, ConstantCall, TrackingInfoValue} from "@shop/common";
export class ConfigurationCalls {
    static META_INFO:MetaInfo = valueMetaInfo('topOffer:ConfigurationCalls', {
        submitCall: 'Call',
        trackingCall: 'Call'
    });

    submitCall:Call<any>;
    trackingCall:Call<TrackingInfoValue>;
}
