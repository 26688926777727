import {PriceDisplayPlainController} from "./PriceDisplayPlainController";
import coPriceDisplaySimpleTemplateHtml from "./coPriceDisplayPlainTemplate.html";

export class CoPriceDisplayPlain implements ng.IComponentOptions {
    bindings:any = {
        type: '@coPriceDisplayType',
        prices: '<coPriceDisplayPrices',
        footnotes:'<coPriceDisplayFootnotes'
    };
    controller:any = PriceDisplayPlainController;
    template:string = coPriceDisplaySimpleTemplateHtml;
}
