import {TariffOptionValue} from "../../../domain/TariffOptionValue";
import {IOffersComponentService} from "../IOffersComponentService";
import _ = require("underscore");
import {ColorOptionValue} from "../../../domain/hardware/ColorOptionValue";
import {PriceContentsValue} from "../../../domain/PriceContentsValue";
import {PriceValue} from "../../../domain/PriceValue";
import { getFormattedDataVolumeGigabytes } from "../../../util/helpers";
import {IDataService} from "../../../services/IDataService";


export class hardwareTileController {
    static $inject:string[] = ['offersComponentService', 'dataService'];

    private id:string;
    public prices:PriceValue;
    public _type:string;
    public footnotes:string;
    private entries: TariffOptionValue[] = [];

    constructor(private offersComponentService:IOffersComponentService, private dataService: IDataService) {
    }
    public getTariffOptionEntries():TariffOptionValue[] {
        return this.offersComponentService.getTariffOptionEntries(this.id);
    };

    public getFormattedDataVolumeGigabytes(dataVolumeMbNumeric: string, tariffName: string) {
        return getFormattedDataVolumeGigabytes(
            dataVolumeMbNumeric,
            tariffName,
            ''
        );
    }

    public hasTariffOptions() {
        return this.getEntries().length > 0;
    }
    public getEntries():TariffOptionValue[] {
        return this.entries;
    };
    public getPrices():PriceValue {
        return this.offersComponentService.getPrices(this.id);
    }
    public getFootnotes():string {
        return this.offersComponentService.getFootnotes(this.id);
    }
    public getHardwareDescription():string {
        return this.offersComponentService.getHardwareDescription(this.id);
    };

    public getActivationFeeDescription(){
        return this.getContents().activationFeeDescription;
    }

    private getContents():PriceContentsValue {
        return this.prices.contents;
    }

    public getHardwareName():string {
        return this.offersComponentService.getHardwareName(this.id);
    }

    public getHardwareImageLink():string {
        return this.offersComponentService.getHardwareImageLink(this.id);
    }

    public getHardwareColors():ColorOptionValue[] {
        return this.offersComponentService.getHardwareColors(this.id);
    };
    public getHardwarePdpLink():string {
        return this.offersComponentService.getHardwarePdpLink(this.id);
    }
    public getTariffDataVolume():string {
        return this.offersComponentService.getTariffDataVolume(this.id);
    }

    public getSchemaData():any {
        let rawSchema = JSON.parse(this.dataService.getSchemaData(this.id));
        let finalRawData = JSON.stringify(rawSchema);
        let htmlData = "<script type='application/ld+json'>" + finalRawData + "</script>"
        return htmlData.toString()
    }
}
