import {enumMetaInfo, MetaInfo} from "@shop/common";
import _ = require("underscore");
export class LogicType {

    static META_INFO:MetaInfo = enumMetaInfo('topOffer:LogicType');

    private static _values:{ [s:string]:LogicType; } = {};

    static forName(obj):LogicType {
        // @ts-ignore
        argsContract(arguments, 'string | null');
        if (obj === null) {
            return null;
        }
        return LogicType._values[obj];
    }

    static values():LogicType[] {
        return _.values(LogicType._values);
    }


    static AND:LogicType = new LogicType('AND');
    static OR:LogicType = new LogicType('OR');

    constructor(public  name:string) {
        // @ts-ignore
        argsContract(arguments, 'str');
        LogicType._values[name] = this;
    }

}

