import {FilterController} from "./FilterController";
import  coFilterTemplateHtml  from "./coFilterTemplate.html";
import any = jasmine.any;

export class CoFilter implements ng.IComponentOptions {
        controller:any = FilterController;
        template:string = coFilterTemplateHtml;
}


