import {CoOfferCartDisplayController} from "./coOfferCartDisplayController";
import  coOfferCartTemplateHtml  from "./coOfferCartDisplayTemplate.html";

export class CoOfferCartDisplay implements ng.IComponentOptions {
    bindings:any = {
        id: '<coOfferPricesId'
    };
    controller:any = CoOfferCartDisplayController;
    template:string = coOfferCartTemplateHtml;
}
