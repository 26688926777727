import {OfferHardwareController} from "./OfferHardwareController";
import  coOfferHardwareTemplateHtml  from "./coOfferHardwareTemplate.html";

export class CoOfferHardware implements ng.IComponentOptions {
    bindings:any = {
        id: '=coOfferHardwareId'
    };
    controller:any = OfferHardwareController;
    template:string = coOfferHardwareTemplateHtml;
}
