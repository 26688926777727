import {MetaInfo, valueMetaInfo} from "@shop/common";
export class SortingContentsValue {

    static META_INFO:MetaInfo = valueMetaInfo('topOffer:SortingContentsValue', {
        name: 'string',
        title: 'string'
    });

    name:string;
    title:string;
}
    