import { OfferDetailsDescriptionController } from './OfferDetailsDescriptionController';
import  coOfferDetailsDescriptionTemplateHtml  from "./coOfferDetailsDescriptionTemplate.html";

export class CoOfferDetailsDescription implements ng.IComponentOptions {
    bindings:any = {
        id: '=coOfferDetailsDescriptionId'
    };
    controller:any = OfferDetailsDescriptionController;
    template:string = coOfferDetailsDescriptionTemplateHtml;
}
