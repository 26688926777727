import {valueMetaInfo} from '@shop/common';

export class TopOfferSelectionTypeUrl {

    static META_INFO = valueMetaInfo('topOffer:TopOfferSelectionTypeUrl', {
        bundleHwCatalogUrl: 'string',
        hardwareOnlyHwCatalogUrl: 'string'
    });

    bundleHwCatalogUrl: string;
    hardwareOnlyHwCatalogUrl: string;
}