import {PackGroupContentsValue} from "./PackGroupContentsValue";
import {MetaInfo, valueMetaInfo} from "@shop/common";
import {PackValue} from "./PackValue";
export class PackGroupValue {

    static META_INFO:MetaInfo = valueMetaInfo('topOffer:PackGroupValue', {
        id: 'string',
        contents: 'PackGroupContentsValue',
        packs: '[PackValue]'

    });

    id:string;
    contents:PackGroupContentsValue;
    packs:PackValue[];

}
