import {MetaInfo, valueMetaInfo} from "@shop/common";
import {MediaGalleryItemContentsValue} from "./MediaGalleryItemContentsValue";
import {MediaGalleryItemType} from "./MediaGalleryItemType";
export class MediaGalleryItemValue {

    static META_INFO:MetaInfo = valueMetaInfo('topOffer:MediaGalleryItemValue', {
        contents: 'MediaGalleryItemContentsValue',
        type: 'MediaGalleryItemType',
        contentAvailable: 'boolean'
    });

    contents:MediaGalleryItemContentsValue;
    type:MediaGalleryItemType;
    contentAvailable: boolean;

}
