import {PriceContentsValue} from "../../../domain/PriceContentsValue";
import {PriceValue} from "../../../domain/PriceValue";
import {IOffersComponentService} from "../IOffersComponentService";

export class PriceDisplayController {
    static $inject:string[] = ['offersComponentService'];

    public prices:PriceValue;
    public _type:string;


    constructor(private offersComponentService:IOffersComponentService) {
    }

    set type(v:string) {
        argsContract(arguments, 'string {{ oneOf("hardware", "tariff", "single") }}');
        this._type = v;
    }

    get type():string {
        return this._type;
    }

    public getPreDescription():string {
        switch (this.type) {
            case 'hardware':
                return this.getContents().hardwarePrePriceDescription;
            case 'tariff':
                return this.getContents().tariffPrePriceDescription;
            case 'single':
                return this.getContents().singlePrePriceDescription;
            default:
                return '';
        }
    }

    public getPostDescription():string {
        switch (this.type) {
            case 'hardware':
                return this.getContents().hardwarePostPriceDescription;
            case 'tariff':
                return this.getContents().tariffPostPriceDescription;
            case 'single':
                return this.getContents().singlePostPriceDescription;
            default:
                return '';
        }
    }

    public getPriceInteger():string {
        return this.offersComponentService.getPriceInteger(this.getPrice());
    }

    public getPriceDecimal():string {
        return this.offersComponentService.getPriceDecimal(this.getPrice());
    }

    private getPrice():number {
        switch (this.type) {
            case 'hardware':
                return this.prices.hardwarePriceAmount;
            case 'tariff':
                return this.prices.tariffPriceAmount;
            case 'single':
                return this.prices.singlePriceAmount;
            default:
                return 0;
        }
    }

    public isTotalPriceDisplayEnabled():boolean {
        return this.offersComponentService.isTotalPriceDisplayEnabled();
    }
    
    private getTotalPrice():number {
        return this.prices.totalPriceAmount;
    }

    private getContents():PriceContentsValue {
        return this.prices.contents;
    }
}
