import {MetaInfo, valueMetaInfo} from "@shop/common";
import {ColorOptionContentsValue} from "./ColorOptionContentsValue";
import {ColorOptionCalls} from "./ColorOptionCalls";
export class ColorOptionValue {

    static META_INFO:MetaInfo = valueMetaInfo('topOffer:ColorOptionValue', {
        contents: 'ColorOptionContentsValue',
        selected: 'boolean',
        calls: 'ColorOptionCalls'
    });

    contents:ColorOptionContentsValue;
    selected:boolean;
    calls:ColorOptionCalls;
}

