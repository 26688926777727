import {IOffersComponentService} from "../IOffersComponentService";
import {ColorOptionValue} from "../../../domain/hardware/ColorOptionValue";
export class OfferHardwareColorsController {
    static $inject:string[] = ['offersComponentService'];

    private id:string;

    constructor(private offersComponentService:IOffersComponentService) {
    }

    public getHardwareColors():ColorOptionValue[] {
        return this.offersComponentService.getHardwareColors(this.id);
    };

    public setHardwareColor(color:ColorOptionValue):void {
        this.offersComponentService.setHardwareColor(this.id, color);
    }
}
