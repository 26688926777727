import {TariffOptionValue} from "../../../domain/TariffOptionValue";
import {IOffersComponentService} from "../IOffersComponentService";
import _ = require("underscore");
import {IDataService} from "../../../services/IDataService";

export class OfferDetailsDescriptionController {
    static $inject:string[] = ['offersComponentService', 'dataService'];

    private id:string;

    constructor(private offersComponentService:IOffersComponentService, private dataService: IDataService) {
    }

    set selectedEntry(entry: TariffOptionValue) {
        this.offersComponentService.selectOptionEntry(this.id, entry);
    }

    get selectedEntry():TariffOptionValue {
        return _.find(this.getTariffOptionEntries(), (entry:TariffOptionValue) => { return entry.selected } );
    }

    public isHardwareOnlySelected():boolean {
        return this.offersComponentService.isHardwareOnlySelected();
    }

    public getHardwareName():string {
        return this.offersComponentService.getHardwareName(this.id);
    };

    public getHardwareDescription():string {
        return this.offersComponentService.getHardwareDescription(this.id);
    };

    public getTariffFeatures():string[] {
        return this.offersComponentService.getTariffFeatures(this.id);
    }

    public getTariffOptionEntries():TariffOptionValue[] {
        return this.offersComponentService.getTariffOptionEntries(this.id);
    };

    public openReviewsModal():void {
        this.offersComponentService.openReviewsModal(this.id);
    }

    public getRatingStars():string {
        return this.offersComponentService.getRatingStars(this.id);
    }
    public getSchemaData():any {
        let rawSchema = JSON.parse(this.dataService.getSchemaData(this.id));
        let finalRawData = JSON.stringify(rawSchema);
        let htmlData = "<script type='application/ld+json'>" + finalRawData + "</script>"
        return htmlData.toString()
    }
}
