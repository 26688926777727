import {OfferStockHintController} from "./OfferStockHintController";
import coOfferStockHintTemplateHtml from "./coOfferStockHintTemplate.html";

export class CoOfferStockHint implements ng.IComponentOptions {
    bindings:any = {
        id: '=coOfferStockHintId'
    };
    controller:any = OfferStockHintController;
    template:string = coOfferStockHintTemplateHtml;
}
