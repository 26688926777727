import {IDataService} from "../../services/IDataService";
import {IProductDetailsComponentService} from "./IProductDetailsComponentService";
import {TechnicalDetailsGroupValue} from "../../domain/hardware/TechnicalDetailsGroupValue";
import {TariffSelectionType} from "../../domain/TariffSelectionType";

import {PackValue} from "../../domain/tariff/PackValue";
import {PackGroupValue} from "../../domain/tariff/PackGroupValue";
import {IConfigurationChangeService} from "../../services/IConfigurationChangeService";
import {IUiStateService} from "../../services/IUiStateService";
import {IListElementService} from "../../services/IListElementService";

export class ProductDetailsComponentService implements IProductDetailsComponentService {
    static $inject:string[] = ['dataService', 'configurationChangeService', 'uiStateService', 'listElementService'];

    constructor(private dataService:IDataService, private configurationChangeService:IConfigurationChangeService, private uiStateService:IUiStateService, private listElementService:IListElementService) {
    }

    public getHardwareName(id:string):string {
        return this.dataService.getHardwareName(id);
    }

    public getHardwareOverviewItems(id:string):string[] {
        return this.dataService.getHardwareOverviewItems(id);
    }

    public getTransformedHardwareTechnicalDetailGroups(id:string):TechnicalDetailsGroupValue[] {
        return this.dataService.getTransformedHardwareTechnicalDetailGroups(id);
    }

    public getTariffName(id:string):string {
        return this.dataService.getTariffName(id);
    }

    public getTariffOverviewItems(id:string):string[] {
        return this.dataService.getTariffOverviewItems(id);
    }

    public getTariffConditions(id:string):string {
        return this.dataService.getTariffConditions(id);
    }

    public getTariffNumberPorting(id:string):string {
        return this.dataService.getTariffNumberPorting(id);
    }

    public getTariffSelection():TariffSelectionType {
        return this.listElementService.getTariffSelection();
    }

    public getPackGroups(id:string):PackGroupValue[] {
        return this.dataService.getPackGroups(id);
    }

    public selectPack(id:string, packGroupIndex:number, packGroup:PackGroupValue, clickedPack:PackValue):void {
        this.configurationChangeService.selectPack(id, packGroupIndex, packGroup, clickedPack);
    }

    public getPriceInteger(price:number):string {
        return this.dataService.getPriceInteger(price);
    }

    public getPriceDecimal(price:number):string {
        return this.dataService.getPriceDecimal(price);
    }

    public isTariffAccordionEntryOpen(id:string, entryId:string):boolean {
        return this.uiStateService.isTariffAccordionEntryOpen(id, entryId);
    }

    public isHardwareAccordionEntryOpen(id:string, entryId:string):boolean {
        return this.uiStateService.isHardwareAccordionEntryOpen(id, entryId);
    }

    public toggleTariffAccordionEntry(id:string, entryId:string):void {
        this.uiStateService.toggleTariffAccordionEntry(id, entryId);
    }

    public toggleHardwareAccordionEntry(id:string, entryId:string):void {
        this.uiStateService.toggleHardwareAccordionEntry(id, entryId);
    }
}


