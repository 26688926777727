import {CoFilter} from './components/filter/coFilter/coFilter';
import {CoOffers} from './components/offers/coOffers/coOffers';
import {CoOfferDetailsDescription} from './components/offers/coOfferDetailsDescription/coOfferDetailsDescription';
import {CoOfferHardware} from './components/offers/coOfferHardware/CoOfferHardware';
import {CoOfferHardwareColors} from './components/offers/coOfferHardwareColors/CoOfferHardwareColors';
import {CoPriceDisplayPlain} from "./components/offers/coPriceDisplayPlain/coPriceDisplayPlain";
import {CoOfferPrices} from './components/offers/coOfferPrices/CoOfferPrices';
import {CoPriceDisplay} from './components/offers/coPriceDisplay/CoPriceDisplay';
import {CoOfferStockHint} from './components/offers/coOfferStockHint/CoOfferStockHint';
import {CoOfferHardwareStorages} from './components/offers/coOfferHardwareStorages/CoOfferHardwareStorages';
import {DataService} from './services/DataService';
import {UiStateService} from './services/UiStateService';
import {classToFactory, IExternalService,EventService, IMappingServiceProvider, ISnapshotService} from "@shop/common";
import {ListElementService} from './services/ListElementService';
import {SortingService} from './services/SortingService';
import {ConfigurationChangeService} from './services/ConfigurationChangeService';
import {FilterComponentService} from './components/filter/FilterComponentService';
import {OffersComponentService} from './components/offers/OffersComponentService';
import {TabsComponentService} from './components/tabs/TabsComponentService';
import {TopOfferPageValue} from './domain/TopOfferPageValue';
import {ConfigurationValue} from './domain/ConfigurationValue';
import {ConfigurationCalls} from './domain/ConfigurationCalls';
import {CurrentConfigurationValue} from './domain/CurrentConfigurationValue';
import {PriceValue} from './domain/PriceValue';
import {PriceContentsValue} from './domain/PriceContentsValue';
import {SortingValue} from './domain/SortingValue';
import {SortingContentsValue} from './domain/SortingContentsValue';
import {TopOfferSelectionTypeUrl} from './domain/TopOfferSelectionTypeUrl';
import {TariffSelectionType} from './domain/TariffSelectionType';
import {CriteriaGroupValue} from './domain/criteria/CriteriaGroupValue';
import {CriteriaValue} from './domain/criteria/CriteriaValue';
import {CriteriaContentsValue} from './domain/criteria/CriteriaContentsValue';
import {LogicType} from './domain/criteria/LogicType';
import {ColorOptionValue} from './domain/hardware/ColorOptionValue';
import {ColorOptionContentsValue} from './domain/hardware/ColorOptionContentsValue';
import {ColorOptionCalls} from './domain/hardware/ColorOptionCalls';
import {HardwareContentsValue} from './domain/hardware/HardwareContentsValue';
import {HardwareValue} from './domain/hardware/HardwareValue';
import {MediaGalleryItemType} from './domain/hardware/MediaGalleryItemType';
import {MediaGalleryItemValue} from './domain/hardware/MediaGalleryItemValue';
import {MediaGalleryItemContentsValue} from './domain/hardware/MediaGalleryItemContentsValue';
import {MediaGalleryValue} from './domain/hardware/MediaGalleryValue';
import {StockHintType} from './domain/hardware/StockHintType';
import {StockHintValue} from './domain/hardware/StockHintValue';
import {StockHintContentsValue} from './domain/hardware/StockHintContentsValue';
import {StorageOptionValue} from './domain/hardware/StorageOptionValue';
import {StorageOptionContentsValue} from './domain/hardware/StorageOptionContentsValue';
import {StorageOptionCalls} from './domain/hardware/StorageOptionCalls';
import {PackGroupValue} from './domain/tariff/PackGroupValue';
import {PackGroupContentsValue} from './domain/tariff/PackGroupContentsValue';
import {PackValue} from './domain/tariff/PackValue';
import {PackContentsValue} from './domain/tariff/PackContentsValue';
import {PackCalls} from './domain/tariff/PackCalls';
import {TariffContentsValue} from './domain/tariff/TariffContentsValue';
import {TariffValue} from './domain/tariff/TariffValue';
import {TariffOptionValue} from './domain/TariffOptionValue';
import {TariffOptionContentsValue} from './domain/TariffOptionContentsValue';
import {TariffOptionCalls} from './domain/TariffOptionCalls';
import {CoProductDetails} from './components/productDetails/coProductDetails/coProductDetails';
import {CoHardwareDetails} from './components/productDetails/coHardwareDetails/coHardwareDetails';
import {ProductDetailsComponentService} from './components/productDetails/ProductDetailsComponentService';
import {BooleanTechnicalDetailsValue} from './domain/hardware/BooleanTechnicalDetailsValue';
import {EmptyTechnicalDetailsValue} from './domain/hardware/EmptyTechnicalDetailsValue';
import {StringTechnicalDetailsValue} from './domain/hardware/StringTechnicalDetailsValue';
import {TechnicalDetailsGroupType} from './domain/hardware/TechnicalDetailsGroupType';
import {TechnicalDetailsGroupValue} from './domain/hardware/TechnicalDetailsGroupValue';
import {CoTariffDetails} from './components/productDetails/coTariffDetails/coTariffDetails';
import {CoTariffPacks} from './components/productDetails/coTariffPacks/coTariffPacks';
import {CoTariffOptions} from './components/filter/coTariffOptions/CoTariffOptions';
import {coHardwareMedia} from './components/offers/coHardwareMedia/coHardwareMedia';
import {ListElementValue} from './domain/ListElementValue';
import {CoTabs} from './components/tabs/coTabs/coTabs';
import {PageCalls} from './domain/PageCalls';
import {CriteriaGroupType} from './domain/criteria/CriteriaGroupType';
import {TopOfferFeatureToggleService} from './services/TopOfferFeatureToggleService';
import {FeatureTogglesValue} from './domain/FeatureTogglesValue';
import {CoTopOffer} from "./components/topOffer/coTopOffer/coTopOffer";
import {TopOfferComponentService} from "./components/topOffer/TopOfferComponentService";
import {App} from "./app/App";
import {TargetGroupSwitcherValue} from "./domain/TargetGroupSwitcherValue";
import {CoTargetGroupSwitcher} from "./components/targetGroupSwitcher/coTargetGroupSwitcher/coTargetGroupSwitcher";
import {TargetGroupSwitcherComponentService} from "./components/targetGroupSwitcher/TargetGroupSwitcherComponentService";
import {CoTariffSwitcher} from "./components/offers/coTariffSwitcher/coTariffSwitcher";
import {CoOfferCartDisplay} from "./components/offers/coOfferCartDisplay/coOfferCartDisplay";
import {EventListenerRegistry, Events} from "./tracking/EventListenerRegistry";
import {TopOfferTrackingService} from "./tracking/TopOfferTrackingService";
import angular = require('angular');
import {EcommerceProductValue} from "./domain/EcommerceProductValue";
import {LoginStatusTrackingValue} from "./domain/LoginStatusTrackingValue";
import {CoNewOffers} from "./components/offers/coNewOffers/coNewOffers";
import {HardwareTile} from "./components/offers/coHardwareTile/hardwareTile";
import {CoPriceSection} from "./components/offers/coPriceSection/coPriceSection";

angular.module('commerce_top_offer', ['commerce_common', 'infinite-scroll'])

    .directive('coHardwareMedia', coHardwareMedia)

    .component('coTopOffer', new CoTopOffer())
    .component('coTabs', new CoTabs())
    .component('coTargetGroupSwitcher', new CoTargetGroupSwitcher())
    .component("coTariffSwitcher",new CoTariffSwitcher())
    .component('coFilter', new CoFilter())
    .component('coOffers', new CoOffers())
    .component('coNewOffers', new CoNewOffers())
    .component('coHardwareTile', new HardwareTile())
    .component('coOfferDetailsDescription', new CoOfferDetailsDescription())
    .component('coOfferHardware', new CoOfferHardware())
    .component('coOfferHardwareColors', new CoOfferHardwareColors())
    .component('coOfferHardwareStorages', new CoOfferHardwareStorages())
    .component('coOfferPrices', new CoOfferPrices())
    .component('coPriceDisplay', new CoPriceDisplay())
    .component('coPriceDisplayPlain', new CoPriceDisplayPlain())
    .component('coPriceSection', new CoPriceSection())
    .component("coOfferCartDisplay", new CoOfferCartDisplay())
    .component('coOfferStockHint', new CoOfferStockHint())
    .component('coProductDetails', new CoProductDetails())
    .component('coTariffDetails', new CoTariffDetails())
    .component('coTariffPacks', new CoTariffPacks())
    .component('coHardwareDetails', new CoHardwareDetails())
    .component('coTariffOptions', new CoTariffOptions())
    .component('app', new App())

    .factory('dataService', classToFactory(DataService))
    .factory('uiStateService', classToFactory(UiStateService))
    .factory('listElementService', classToFactory(ListElementService))
    .factory('sortingService', classToFactory(SortingService))
    .factory('topOfferComponentService', classToFactory(TopOfferComponentService))
    .factory('configurationChangeService', classToFactory(ConfigurationChangeService))
    .factory('filterComponentService', classToFactory(FilterComponentService))
    .factory('offersComponentService', classToFactory(OffersComponentService))
    .factory('tabsComponentService', classToFactory(TabsComponentService))
    .factory('targetGroupSwitcherComponentService', classToFactory(TargetGroupSwitcherComponentService))
    .factory('productDetailsComponentService', classToFactory(ProductDetailsComponentService))
    .factory('topOfferFeatureToggleService', classToFactory(TopOfferFeatureToggleService))

    .factory('eventListenerRegistry', classToFactory(EventListenerRegistry))
    .service('topOfferTrackingService', classToFactory(TopOfferTrackingService))

    .run(['eventListenerRegistry', angular.noop])
    .run(['eventService', (eventService: EventService) => {
            eventService.emit(Events.TOP_OFFER_TRACKING_INITIALIZED);
    }])

    .config(['mappingServiceProvider', function (mappingServiceProvider: IMappingServiceProvider) {
        //region mappings
        mappingServiceProvider.addAnnotatedType(TopOfferPageValue);
        mappingServiceProvider.addAnnotatedType(LoginStatusTrackingValue);
        mappingServiceProvider.addAnnotatedType(ListElementValue);
        mappingServiceProvider.addAnnotatedType(ConfigurationValue);
        mappingServiceProvider.addAnnotatedType(EcommerceProductValue);
        mappingServiceProvider.addAnnotatedType(ConfigurationCalls);
        mappingServiceProvider.addAnnotatedType(PageCalls);
        mappingServiceProvider.addAnnotatedType(CurrentConfigurationValue);
        mappingServiceProvider.addAnnotatedType(PriceValue);
        mappingServiceProvider.addAnnotatedType(PriceContentsValue);
        mappingServiceProvider.addAnnotatedType(SortingValue);
        mappingServiceProvider.addAnnotatedType(SortingContentsValue);
        mappingServiceProvider.addAnnotatedType(TopOfferSelectionTypeUrl);
        mappingServiceProvider.addAnnotatedType(TariffSelectionType);
        mappingServiceProvider.addAnnotatedType(FeatureTogglesValue);
        mappingServiceProvider.addAnnotatedType(TargetGroupSwitcherValue);
        // criteria
        mappingServiceProvider.addAnnotatedType(CriteriaGroupValue);
        mappingServiceProvider.addAnnotatedType(CriteriaValue);
        mappingServiceProvider.addAnnotatedType(CriteriaGroupType);
        mappingServiceProvider.addAnnotatedType(CriteriaContentsValue);
        mappingServiceProvider.addAnnotatedType(LogicType);
        // hardware
        mappingServiceProvider.addAnnotatedType(BooleanTechnicalDetailsValue);
        mappingServiceProvider.addAnnotatedType(ColorOptionValue);
        mappingServiceProvider.addAnnotatedType(ColorOptionContentsValue);
        mappingServiceProvider.addAnnotatedType(ColorOptionCalls);
        mappingServiceProvider.addAnnotatedType(EmptyTechnicalDetailsValue);
        mappingServiceProvider.addAnnotatedType(HardwareContentsValue);
        mappingServiceProvider.addAnnotatedType(HardwareValue);
        mappingServiceProvider.addAnnotatedType(MediaGalleryItemType);
        mappingServiceProvider.addAnnotatedType(MediaGalleryItemValue);
        mappingServiceProvider.addAnnotatedType(MediaGalleryItemContentsValue);
        mappingServiceProvider.addAnnotatedType(MediaGalleryValue);
        mappingServiceProvider.addAnnotatedType(StockHintType);
        mappingServiceProvider.addAnnotatedType(StockHintValue);
        mappingServiceProvider.addAnnotatedType(StockHintContentsValue);
        mappingServiceProvider.addAnnotatedType(StorageOptionValue);
        mappingServiceProvider.addAnnotatedType(StorageOptionContentsValue);
        mappingServiceProvider.addAnnotatedType(StringTechnicalDetailsValue);
        mappingServiceProvider.addAnnotatedType(TechnicalDetailsGroupType);
        mappingServiceProvider.addAnnotatedType(TechnicalDetailsGroupValue);
        mappingServiceProvider.addAnnotatedType(StorageOptionCalls);
        // tariff
        mappingServiceProvider.addAnnotatedType(PackGroupValue);
        mappingServiceProvider.addAnnotatedType(PackGroupContentsValue);
        mappingServiceProvider.addAnnotatedType(PackValue);
        mappingServiceProvider.addAnnotatedType(PackContentsValue);
        mappingServiceProvider.addAnnotatedType(PackCalls);
        mappingServiceProvider.addAnnotatedType(TariffContentsValue);
        mappingServiceProvider.addAnnotatedType(TariffValue);
        mappingServiceProvider.addAnnotatedType(TariffOptionValue);
        mappingServiceProvider.addAnnotatedType(TariffOptionContentsValue);
        mappingServiceProvider.addAnnotatedType(TariffOptionCalls);
        //endregion mappings
    }])
    .run(['snapshotService', function (snapshotService: ISnapshotService) {
        snapshotService.snapshot();
    }])
    .run(['externalService', (externalService: IExternalService) => {
        externalService.initExternalApps();
    }]);
