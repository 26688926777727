(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@shop/common"), require("underscore"), require("angular"));
	else if(typeof define === 'function' && define.amd)
		define(["@shop/common", "underscore", "angular"], factory);
	else if(typeof exports === 'object')
		exports["topoffer"] = factory(require("@shop/common"), require("underscore"), require("angular"));
	else
		root["topoffer"] = factory(root["common"], root["_"], root["angular"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__135__) {
return 