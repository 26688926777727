import {valueMetaInfo} from "@shop/common";
import {TechnicalDetailsGroupType} from "./TechnicalDetailsGroupType";
import {AbstractTechnicalDetailsValue} from "./AbstractTechnicalDetailsValue";

export class TechnicalDetailsGroupValue {

    static META_INFO = valueMetaInfo('topOffer:TechnicalDetailsGroupValue', {
        name: 'string | null',
        technicalDetailsGroupType: 'TechnicalDetailsGroupType | null',
        technicalDetails: 'AbstractTechnicalDetailsValue[] | null'
    });

    name:string;
    technicalDetailsGroupType:TechnicalDetailsGroupType;
    technicalDetails:AbstractTechnicalDetailsValue[];
}

