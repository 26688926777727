import {AbstractTechnicalDetailsValue} from "./AbstractTechnicalDetailsValue";
import {valueMetaInfo} from "@shop/common";

export class BooleanTechnicalDetailsValue extends AbstractTechnicalDetailsValue {

    static META_INFO = valueMetaInfo('topOffer:BooleanTechnicalDetailsValue', {
        keyName: 'string | null',
        keyValue: 'boolean | null'
    });

    keyValue:boolean;

    public isBooleanTypedField():boolean {
        return true;
    }
}
