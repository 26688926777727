import {ITargetGroupSwitcherComponentService} from "../ITargetGroupSwitcherComponentService";

export class TargetGroupSwitcherController {
    static $inject: string[] = ['targetGroupSwitcherComponentService'];

    constructor(private targetGroupSwitcherService: ITargetGroupSwitcherComponentService) {
    }

    public switchTargetGroup(): void {
        this.targetGroupSwitcherService.switchTargetGroup();
    }

    public bannerMarkup(): string {
        return this.targetGroupSwitcherService.getTargetGroupSwitcherValue().bannerMarkup;
    }

    public visible(): boolean {
        return this.targetGroupSwitcherService.getTargetGroupSwitcherValue().visible;
    }

    public targetGroupName(): string {
        return this.targetGroupSwitcherService.getTargetGroupSwitcherValue().targetGroup;
    }
}
