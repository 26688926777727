import {ITopOfferComponentService} from "./ITopOfferComponentService";
import {IDataService} from "../../services/IDataService";
import {ITopOfferFeatureToggleService} from "../../services/ITopOfferFeatureToggleService";
export class TopOfferComponentService implements ITopOfferComponentService {
    static $inject:string[] = ["dataService","topOfferFeatureToggleService"];

    constructor(private dataService:IDataService,
                private topOfferFeatureToggleService:ITopOfferFeatureToggleService,
                ) {}

    public getSeoBottomTeaserText():string {
        return this.dataService.getSeoBottomTeaserText();
    }
    public getPlpToggleValue():boolean{
        return this.topOfferFeatureToggleService.isNewBlauPlpEnabled()
    }

}
