import {PriceSectionController} from "./PriceSectionController";
import coPriceSectionTemplateHtml from "./coPriceSection.html";

export class CoPriceSection implements ng.IComponentOptions {
    bindings:any = {
        type: '@coPriceDisplayType',
        prices: '<coPriceDisplayPrices',
        footnotes:'<coPriceDisplayFootnotes'
    };
    controller:any = PriceSectionController;
    template:string = coPriceSectionTemplateHtml;
}
