import {MetaInfo, valueMetaInfo} from "@shop/common";
export class CriteriaContentsValue {

    static META_INFO:MetaInfo = valueMetaInfo('topOffer:CriteriaContentsValue', {
        name: 'string',
        title: 'string',
        value: 'string'
    });

    name:string;
    title:string;
    value:string;
}
