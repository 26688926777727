import {MetaInfo, valueMetaInfo} from "@shop/common";
export class PriceContentsValue {
    static META_INFO:MetaInfo = valueMetaInfo('topOffer:PriceContentsValue', {
        hardwarePrePriceDescription: 'string | null',
        hardwarePostPriceDescription: 'string | null',
        tariffPrePriceDescription: 'string | null',
        tariffPostPriceDescription: 'string | null',
        singlePrePriceDescription: 'string | null',
        singlePostPriceDescription: 'string | null',
        contractDurationDescription: 'string | null',
        activationFeeDescription: 'string | null'
    });

    hardwarePrePriceDescription:string;
    hardwarePostPriceDescription:string;
    tariffPrePriceDescription:string;
    tariffPostPriceDescription:string;
    singlePrePriceDescription:string;
    singlePostPriceDescription:string;
    contractDurationDescription:string;
    activationFeeDescription:string;
}
