import {IProductDetailsComponentService} from "../IProductDetailsComponentService";
import {TariffSelectionType} from "../../../domain/TariffSelectionType";

export class ProductDetailsController {
    static $inject:string[] = ['productDetailsComponentService'];

    private id:string;

    constructor(private productDetailsComponentService:IProductDetailsComponentService) {
    }

    public displayTariffDetails():boolean {
        return this.productDetailsComponentService.getTariffSelection() !== TariffSelectionType.HARDWARE_ONLY;
    }
}
