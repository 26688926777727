import {CriteriaValue} from "../domain/criteria/CriteriaValue";
import {ListElementValue} from "../domain/ListElementValue";
import {TariffSelectionType} from "../domain/TariffSelectionType";
import {TopOfferPageValue} from "../domain/TopOfferPageValue";
import {CriteriaGroupValue} from "../domain/criteria/CriteriaGroupValue";
export interface IListElementService {

    getVisibleListElements():ListElementValue[];
    toggleCriteriaValue(criteriaKeyValue:string):void;
    getCriteriaValues():CriteriaGroupValue[];
    resetCriteriaSelection():void;
    getTariffSelection():TariffSelectionType;
    setTariffSelection(type:TariffSelectionType):void;
    showMoreRows():void;
    moreRowsToShow():boolean;
    switchManufacturer(criteriaKeyValue:string):void;
    showAllManufacturer():void;
    isPostpaidSelected():boolean;
    isPrepaidSelected():boolean;
    isHardwareOnlySelected():boolean;
    getSortingEntries():SortingEntry[];
    setSelectedSortingEntry(entry:SortingEntry):void;
    getSelectedSortingEntry():SortingEntry;
    getCurrentCriteriaValue(): string;
}

export class SortingEntry {
    constructor(
        public title:string,
        public sortingName:string,
        public descending:boolean
    ){}
}