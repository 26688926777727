
import {MetaInfo, valueMetaInfo} from "@shop/common";
export class ListElementValue {

    static META_INFO:MetaInfo = valueMetaInfo('topOffer:ListElementValue', {
        id: 'string',
        initialConfigurationId:'string',
        indexBySortingName: '{*: number}',
        criteriaValues:'string[]',
    });

    id: string;
    initialConfigurationId: string;
    indexBySortingName:{ [s:string]:number; };
    criteriaValues:string[];
}

