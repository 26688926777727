import {MetaInfo, valueMetaInfo} from "@shop/common";
import {StorageOptionContentsValue} from "./StorageOptionContentsValue";
import {StorageOptionCalls} from "./StorageOptionCalls";
export class StorageOptionValue {
    static META_INFO:MetaInfo = valueMetaInfo('topOffer:StorageOptionValue', {
        contents: 'StorageOptionContentsValue',
        selected: 'boolean',
        calls: 'StorageOptionCalls'
    });

    contents:StorageOptionContentsValue;
    selected:boolean;
    calls:StorageOptionCalls;
}
