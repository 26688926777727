import {MetaInfo, valueMetaInfo} from "@shop/common";
import {PriceContentsValue} from "./PriceContentsValue";
export class PriceValue {

    static META_INFO:MetaInfo = valueMetaInfo('topOffer:PriceValue', {
        singlePriceAmount: 'number | null',
        tariffPriceAmount: 'number | null',
        hardwarePriceAmount: 'number | null',
        totalPriceAmount: 'number | null',
        contents: 'PriceContentsValue',
        nonRecurringAmount:'number | null'
    });

    singlePriceAmount:number;
    hardwarePriceAmount:number;
    tariffPriceAmount:number;
    totalPriceAmount:number;
    nonRecurringAmount:number;
    contents:PriceContentsValue;
}
