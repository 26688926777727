import {MetaInfo, valueMetaInfo} from "@shop/common";
export class TariffOptionContentsValue {

    static META_INFO:MetaInfo = valueMetaInfo('topOffer:TariffOptionContentsValue', {
        name: 'string',
        tariffDataVolumeMbNumeric: 'string | null'
    });

    name:string;
    tariffDataVolumeMbNumeric:string;
}
