

/*
 * The API call for the numeric data volume might not return content for some tariffs, or being plain text it could return words.
 * Since this is not supported in this UI we can provide an alternative text to be displayed instead
 */
export function getFormattedDataVolumeGigabytes(
    datVolumeMbNumeric: string,
    alternativeDisplayText: string,
    suffixLTE: string = ' LTE'
): string {
  const trimmedDatVolumeMbNumeric = datVolumeMbNumeric
    .replace(/\s+/g, "")
    .trim();

  if (trimmedDatVolumeMbNumeric === "-1") {
    return "Unbegrenzt LTE";
  }

  if (
      trimmedDatVolumeMbNumeric.length === 0 ||
      !trimmedDatVolumeMbNumeric.match(/^[0-9]+$/)
  ) {
    return alternativeDisplayText;
  }

  /*
   * As agreed upon with Pixie the CMS API-call for the data volume will return data volumes as follows:
   * - "1024" for 1 GB
   * - "2048" for 2 GB
   * - ...
   */
  return `${Math.floor(parseInt(trimmedDatVolumeMbNumeric) / 1024)} GB${suffixLTE}`;
}

