import {IOffersComponentService} from "../IOffersComponentService";
import {StorageOptionValue} from "../../../domain/hardware/StorageOptionValue";
export class OfferHardwareStoragesController {
    static $inject:string[] = ['offersComponentService'];

    private id:string;

    constructor(private offersComponentService:IOffersComponentService) {
    }

    public getHardwareStorages():StorageOptionValue[] {
        return this.offersComponentService.getHardwareStorages(this.id);
    };

    public setHardwareStorage(storage:StorageOptionValue):void {
        this.offersComponentService.setHardwareStorage(this.id, storage);
    }
}
