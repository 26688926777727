import {MetaInfo, valueMetaInfo} from "@shop/common";
export class CurrentConfigurationValue {

    static META_INFO:MetaInfo = valueMetaInfo('topOffer:CurrentConfigurationValue', {
        shopContext: 'string',
        targetGroup: 'string',
        hardwareOfferName: 'string',
        tariffOfferName: 'string | null',
        selectedTariffPackNames: '[string] | null'
    });

    shopContext:string;
    targetGroup:string;
    hardwareOfferName:string;
    tariffOfferName:string;
    selectedTariffPackNames:string[];
}
