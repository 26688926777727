import {MetaInfo, enumMetaInfo} from "@shop/common";
export class TariffSelectionType {

    static META_INFO:MetaInfo = enumMetaInfo('topOffer:TariffSelectionType');

    static POSTPAID:TariffSelectionType = new TariffSelectionType('POSTPAID');
    static PREPAID:TariffSelectionType = new TariffSelectionType('PREPAID');
    static HARDWARE_ONLY:TariffSelectionType = new TariffSelectionType('HARDWARE_ONLY');

    constructor(public name:string) {
        argsContract(arguments, 'str');
    }
}
