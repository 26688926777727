import {IProductDetailsComponentService} from '../IProductDetailsComponentService';
import {PackGroupValue} from "../../../domain/tariff/PackGroupValue";
import {PackValue} from "../../../domain/tariff/PackValue";

export class TariffPacksController {
    static $inject:string[] = ['productDetailsComponentService'];

    public packGroup:PackGroupValue;
    public packGroupIndex:number;
    private id:string;

    constructor(private productDetailsComponentService:IProductDetailsComponentService) {
    }

    public getPacks():PackValue[] {
        return this.packGroup.packs;
    }

    public selectPack(pack:PackValue):void {
        this.productDetailsComponentService.selectPack(this.id, this.packGroupIndex, this.packGroup, pack);
    }

    public getPackName(pack:PackValue):string {
        return pack.contents.name;
    }

    public getPackDescription(pack:PackValue):string {
        return pack.contents.description;
    }

    public getPackDetails(pack:PackValue):string {
        return pack.contents.details;
    }

    public getPackPriceInteger(pack:PackValue):string {
        return this.productDetailsComponentService.getPriceInteger(pack.contents.price);
    }

    public getPackPriceDecimal(pack:PackValue):string {
        return this.productDetailsComponentService.getPriceDecimal(pack.contents.price);
    }
    
}
