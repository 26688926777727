import {IFilterComponentService} from "../IFilterComponentService";
import {CriteriaValue} from "../../../domain/criteria/CriteriaValue";
import {CriteriaGroupValue} from "../../../domain/criteria/CriteriaGroupValue";
import {SortingEntry} from "../../../services/IListElementService";
export class FilterController {
    static $inject:string[] = ['filterComponentService'];
    public _manufacturer:string;

    constructor(private filterComponentService:IFilterComponentService) {
    }

    set manufacturer(c:string) {
        if(c === null) {
            this.filterComponentService.showAllManufacturer();
        } else {
            this.filterComponentService.switchManufacturer(c);
        }
        this._manufacturer = c;
    }

    get manufacturer():string {
        return this._manufacturer;
    }

    set selectedSortingEntry(entry: SortingEntry) {
        this.filterComponentService.setSelectedSortingEntry(entry);
    }

    get selectedSortingEntry():SortingEntry {
        return this.filterComponentService.getSelectedSortingEntry();
    }

    public getSortingEntries():SortingEntry[] {
        return this.filterComponentService.getSortingEntries();
    }

    public toggleCriteriaValue(criteriaValue:CriteriaValue):void {
        this.filterComponentService.toggleCriteriaValues(criteriaValue.criteriaKeyValue);
    }

    public getCriteriaValue():CriteriaGroupValue[] {
        return this.filterComponentService.getCriteriaValues();
    }

    public resetCriteriaSelection():void {
        this.filterComponentService.resetCriteriaSelection();
    }

    public getManufacturerCriteriaValues():{[csSclass:string]:CriteriaValue} {
        return this.filterComponentService.getManufacturerCriteriaValues();
    }

    public isFilterDisabled():boolean {
        return this.filterComponentService.isFilterDisabled();
    }

    public isTariffOptionSwitchEnabled():boolean {
        return this.filterComponentService.isTariffOptionSwitchEnabled();
    }

    public navigateToAllDevices():void {
        return this.filterComponentService.navigateToAllDevices();
    }
}
