import {MetaInfo, valueMetaInfo} from "@shop/common";
import {HardwareContentsValue} from "./HardwareContentsValue";
import {ColorOptionValue} from "./ColorOptionValue";
import {StorageOptionValue} from "./StorageOptionValue";
import {MediaGalleryValue} from "./MediaGalleryValue";
import {StockHintValue} from "./StockHintValue";
import {TechnicalDetailsGroupValue} from "./TechnicalDetailsGroupValue";

export class HardwareValue {

    static META_INFO:MetaInfo = valueMetaInfo('topOffer:HardwareValue', {
        contents: 'HardwareContentsValue',
        colorOptions: '[ColorOptionValue]',
        storageOptions: '[StorageOptionValue]',
        mediaGallery: 'MediaGalleryValue',
        technicalDetailGroups: 'TechnicalDetailsGroupValue[]',
        stockHint: 'StockHintValue'
    });

    contents:HardwareContentsValue;
    colorOptions:ColorOptionValue[];
    storageOptions:StorageOptionValue[];
    mediaGallery:MediaGalleryValue;
    technicalDetailGroups:TechnicalDetailsGroupValue[];
    stockHint:StockHintValue;
}
